import { BlurPass, FboUtils, RenderComposer, SceneUtils } from '@jocabola/gfx';
import { GLTFAsset, KTX2Asset, TextureAsset, TextureOptions } from '@jocabola/io';
import { MathUtils, Random } from '@jocabola/math';
import { isIpad, isMobile, isTouchDevice } from '@jocabola/utils';
import { gsap } from 'gsap';
import { ACESFilmicToneMapping, AnimationAction, AnimationMixer, Color, DirectionalLight, EquirectangularReflectionMapping, LoopOnce, Mesh, MeshBasicMaterial, RGBAFormat, Texture, TextureLoader, Vector2, Vector4, WebGLRenderer, WebGLRenderTarget } from 'three';
import { ASSETS_URL, CURRENT_PAGE, GLB_EXT, PALETTES, SCROLLER } from '../../core/Global';
import CompositionPass from '../gfx/CompositionPass';
import CustomVideoTexture from '../gfx/CustomVideoTexture';
import { BACKDROP, CONE, DEBUG_MAT, HOLO, PBR, PRNT, PRTSCR, TV_SCREEN } from '../gfx/ShaderLib';
import { VHS } from '../gfx/VHS';
import { css3D, Text3D } from '../ui/css3D';
import glScene from './glScene';
import CameraController from './home/CameraController';
import { addIObject, down3D, holoI, hover3D, up3D } from './home/Interactive3D';

const baseURL = `${ASSETS_URL}basis/`;

const TONE_MAPPING = ACESFilmicToneMapping;
const EXPOSURE = 2.75;
// const ENCODING = sRGBEncoding;

// FBO SCREEN
const FS = 512;
const TV_SCR_MAT = PBR.clone();

const MOUSE_EASING = .016;

const SHOW_WHOLE_SCENE = false;

const transition = {
	active: false,
	value: 0,
}

const mats = {
	printer: null,
	printer_scr: null,
	lastPrinted: 0
}

const _3dTexts = [];

const _isMobile = isMobile();
const textureLoader = new TextureLoader();

const spritesheet = isMobile() ? `printer_sprite-mobile` : `printer_sprite`;

const viewport = new Vector4();

export default class glHome extends glScene {
	cc: CameraController;
	holoFbo: WebGLRenderTarget;
	composer: RenderComposer;
	holos:Array<Mesh> = new Array();
	noHolos:Array<Mesh> = new Array();
	comp:CompositionPass;
	blur:BlurPass;
	mouse:Vector2 = new Vector2();
	mouseTarget:Vector2  = new Vector2();
	screenFbo:WebGLRenderTarget;
	knob:Mesh;
	rnd = Random.random();
	previousHighlighted: number = null;
	videoMap = {};
	videoMapCreated:boolean = false;
	computerVideoTex:WebGLRenderTarget;
	printing:boolean = false;
	mixer:AnimationMixer;
	action:AnimationAction;
	prints:Array<Mesh> = [];
	currentPrint:Mesh = null;
	texts3D:Array<Text3D> = [];
	vhs:VHS;
	offset:number = 0;
	private videoMapL = {
		loaded: -1,
		totals: 0
	}

	constructor(width: number, height: number, renderer:WebGLRenderer, hdri:KTX2Asset) {
		super(width, height, renderer, hdri);
		this.cc = new CameraController(width, height);
		this.scene.add(this.cc.camera);

		renderer.getViewport(viewport);

		this.toneMapping.mode = TONE_MAPPING;
		this.toneMapping.exposure = EXPOSURE;

		this.addAssets();

		this.holoFbo = FboUtils.getRenderTarget(width, height, {
			format: RGBAFormat
		}, true);

		this.screenFbo = FboUtils.getRenderTarget(FS, FS);
		this.computerVideoTex = FboUtils.getRenderTarget(FS, FS);

		this.composer = new RenderComposer(1, 1);
		this.blur = new BlurPass(this.holoFbo.texture, width, height, {
			scale: .1,
			radius: 1,
			iterations: 4,
			quality: 0
		});
		this.comp = new CompositionPass(this.holoFbo.texture);

		/* const light = new DirectionalLight(0xffffff, .6);
		light.position.set(0, 100, 10);
		this.scene.add(light); */

		this.load();
	}

	private addAssets () {
		const texopts:TextureOptions = {
			flipY: false
		}

		// GLTF SCENE, HDRI + HOLOGRAMS
		const gltf = new GLTFAsset(`${ASSETS_URL}models/main_scene.${GLB_EXT}`);
		const hdri = this.hdri;
		const ashC = new KTX2Asset(`${baseURL}hologram/ash.ktx2`, texopts);
		const veroC = new KTX2Asset(`${baseURL}hologram/veronica.ktx2`, texopts);
		this._assets.add(gltf);
		this._assets.add(hdri);
		this._assets.add(ashC);
		this._assets.add(veroC);

		// Hologram Base
		this._assets.add(
			new KTX2Asset(`${baseURL}hologram/color.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}hologram/normal.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}hologram/alpha.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}hologram/aorm.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}hologram/emissive.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}hologram/ash_shadow.ktx2`, {
				flipY: false,
				format: RGBAFormat
			})
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}hologram/veronica_shadow.ktx2`, {
				flipY: false,
				format: RGBAFormat
			})
		);

		// TV
		this._assets.add(
			new KTX2Asset(`${baseURL}tv/ground_plane.ktx2`, {
				flipY: false,
				format: RGBAFormat
			})
			/* new TextureAsset(`${baseURL}tv/ground_plane.webp`, {
				flipY: false,
				format: RGBAFormat
			}) */
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}tv/screen_basecolor.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}tv/screen_normal.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}tv/screen_roughness.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}tv/tv_basecolor.ktx2`, texopts)
		);
		this._assets.add(
			new TextureAsset(`${baseURL}tv/tv_normal.webp`, texopts)
			// new KTX2Asset(`${baseURL}tv/tv_normal.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}tv/tv_emissive.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}tv/tv_occlusionroughnessmetallic.ktx2`, texopts)
		);

		// printer
		this._assets.add(
			new KTX2Asset(`${baseURL}printer/diffuse.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}printer/emissive.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}printer/normal.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}printer/aorm.ktx2`, texopts)
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}printer/shadow_plane.ktx2`, {
				flipY: false,
				format: RGBAFormat
			})
		);
		this._assets.add(
			new KTX2Asset(`${baseURL}printer/${spritesheet}.ktx2`, texopts)
		);

		for(let i=1; i<4; i++) {
			this._assets.add(
				new KTX2Asset(`${baseURL}printer/prints/AO_print0${i}.ktx2`, texopts)
			);
		}

		this.createVideoMap();

	}

	createVideoMap () {
		if(this.videoMapCreated) return;
		this.videoMapCreated = true;

		const projects = document.querySelectorAll('.video-previews-list [video-preview]');
		this.videoMapL.totals = projects.length;
		this.videoMapL.loaded = 0;

		for(const prj of projects){

			const id = parseInt(prj.getAttribute('video-item'));
			const url = _isMobile ? prj.getAttribute('video-fallback') : prj.getAttribute('video-preview');

			// console.log(url);

			if(!_isMobile) {
				CustomVideoTexture.genVideoTexture(url, (texture) => {
					texture.flipY = false;
					// texture.image.style.display = 'none';
					this.videoMap[id] = texture;
					this.videoMapL.loaded++;

					if(this.videoMapL.loaded === this.videoMapL.totals) document.querySelector('.video-previews-list').remove();
				})
			} else {
				textureLoader.load(url, (texture)=>{
					texture.flipY = false;
					this.videoMap[id] = texture;
					this.videoMapL.loaded++;
				});
			}
		}
	}

	getTextureByPath(path:string):Texture {
		return this._assets.getByURL(baseURL+path).content;
	}

	setHovers () {
		window.addEventListener('projectChange', (e) => {

			const project = e.detail;

			if(project.id !== null){
				this.highlight(project, true);
			} else {
				this.removeHighlight(true);
			}
		})
	}

	highlight (project, isHover=false) {
		if(!this.loaded) return;
		if(transition.active) return;
		if(!this.active) return;
		if(project === this.previousHighlighted && isHover) return;

		const u = TV_SCREEN.uniforms;
		const video = this.videoMap[project.id];
		if(video && !_isMobile) {
			video.image.style.display = 'block';
			video.image.play();
		}

		// set project metadata
		this.vhs.setData(project.year, project.title, project.textColor);

		u.video.value = video;

		gsap.killTweensOf(u.videoP);
		if(isHover && project != this.previousHighlighted) u.videoP.value -= .25;
		gsap.to(u.videoP, {duration: 1, value: 1, ease: "elastic.out"});

		if(this.knob) {
			gsap.killTweensOf(this.knob.rotation);
			gsap.to(this.knob.rotation, {duration: .25, z: Random.randf(-Math.PI/2, Math.PI/2) ,ease: "power4.in"})
		}

		if(!isHover) this.previousHighlighted = project;
	}

	removeHighlight(isHover=false) {
		if(!this.loaded) return;
		if(transition.active) return;
		if(!this.active) return;
		const u = TV_SCREEN.uniforms;
		if(!isHover) this.previousHighlighted = null;
		if(u.video.value != null && !_isMobile) {
			// u.video.value.image.style.display = 'none';
			u.video.value.image.pause();
		}
		if(isHover && this.previousHighlighted != null) {
			this.highlight(this.previousHighlighted);
			return;
		}
		gsap.killTweensOf(u.videoP);
		gsap.to(u.videoP, {duration: 1, value: 0, ease: "elastic.out"});
		if(this.knob) {
			gsap.killTweensOf(this.knob.rotation);
			gsap.to(this.knob.rotation, {duration: .25, z: 0 ,ease: "power4.in"})
		}
	}

	get progress (): number {

		// return this._assets.getProgress();

		const p1 = this._assets.getProgress() * .5;

		// const p2 = IS_DEV_MODE ? 0.5 : this.videoMapL.totals > 0 ? + .5 * this.videoMapL.loaded / this.videoMapL.totals : 0;
		const p2 = this.videoMapL.totals > 0 ? + .5 * this.videoMapL.loaded / this.videoMapL.totals : 0;

		return p1+p2;
	}

	onLoaded() {
		// do my stuff first
		const gltf = this._assets.get(0).content;
		// console.log(gltf.scene);
		this.scene.add(gltf.scene);

		this.vhs = new VHS();

		// printer animation
		this.mixer = new AnimationMixer(gltf.scene);
		this.action = this.mixer.clipAction(gltf.animations[0]);
		this.action.setLoop(LoopOnce, 1);
		// console.log(gltf.animations[0].duration);

		this.mixer.addEventListener( 'finished', ( e ) => {
			this.resetPrinting();
		} );

		const env = SceneUtils.setHDRI(	this._assets.get(1).content,this.renderer);

		for(const asset of this._assets.assets) {
			if (asset.url.indexOf(".ktx2") > -1) {
				// asset.content.encoding = sRGBEncoding;
			}
		}

		/* const env = this._assets.get(1).content;
		env.mapping = EquirectangularReflectionMapping; */

		const envMap = this._assets.get(1).content;
		envMap.mapping = EquirectangularReflectionMapping;

		// this.scene.environment = env;
		// this.scene.background = env;

		const ashC = this._assets.get(2).content;
		const veroC = this._assets.get(3).content;
		HOLO.uniforms.map.value = ashC;

		const holo2 = HOLO.clone();
		holo2.uniforms.map.value = veroC;

		const holoBase = PBR.clone();
		holoBase.emissiveIntensity = 1.6;
		holoBase.metalness = 1.5;
		holoBase.emissive = new Color(0xffffff);
		const aorm = this.getTextureByPath('hologram/aorm.ktx2');
		holoBase.map = this.getTextureByPath('hologram/color.ktx2');
		holoBase.normalMap = this.getTextureByPath('hologram/normal.ktx2');
		holoBase.alphaMap = this.getTextureByPath('hologram/alpha.ktx2');
		holoBase.alphaTest = .1;
		holoBase.envMap = env;
		holoBase.roughnessMap = aorm;
		holoBase.metalnessMap = aorm;
		// holoBase.aoMap = aorm;
		holoBase.emissiveMap = this.getTextureByPath('hologram/emissive.ktx2');
		// holoBase.needsUpdate = true;

		const tvmat = PBR.clone();
		tvmat.map = this.getTextureByPath('tv/tv_basecolor.ktx2');
		// tvmat.bumpMap = this.getTextureByPath('tv/tv_height.ktx2');
		tvmat.normalMap = this.getTextureByPath(`tv/tv_normal.webp`);
		tvmat.emissiveMap = this.getTextureByPath('tv/tv_emissive.ktx2');
		tvmat.emissive.setHex(0xffffff);
		// tvmat.normalMap = this.getTextureByPath(`tv/tv_normal.ktx2`);
		const tv_pbr = this.getTextureByPath('tv/tv_occlusionroughnessmetallic.ktx2');
		tvmat.roughness = 1;
		tvmat.metalness = 1;
		tvmat.roughnessMap = tv_pbr;
		tvmat.metalnessMap = tv_pbr;
		// tvmat.aoMap = tv_pbr;
		tvmat.envMap = env;

		TV_SCREEN.uniforms.map.value = this.getTextureByPath('tv/screen_basecolor.ktx2');
		TV_SCREEN.uniforms.ui.value = this.vhs.texture;

		const screenmat = TV_SCR_MAT;
		screenmat.map = this.screenFbo.texture;
		screenmat.normalMap = this.getTextureByPath('tv/screen_normal.ktx2');
		screenmat.roughnessMap = this.getTextureByPath('tv/screen_roughness.ktx2');
		screenmat.metalness = 0;
		screenmat.envMap = env;

		// Printer Screen
		const prntTex = this.getTextureByPath(`printer/${spritesheet}.ktx2`);

		PRTSCR.uniforms.map.value = prntTex;

		const prmat = PBR.clone();
		prmat.map = this.getTextureByPath('printer/diffuse.ktx2');
		prmat.normalMap = this.getTextureByPath('printer/normal.ktx2');
		prmat.roughnessMap = this.getTextureByPath('printer/aorm.ktx2');
		prmat.metalnessMap = this.getTextureByPath('printer/aorm.ktx2');
		// prmat.aoMap = this.getTextureByPath('printer/aorm.ktx2');
		prmat.emissiveMap = this.getTextureByPath('printer/emissive.ktx2');
		prmat.emissive.setHex(0xffffff);
		prmat.envMap = env;

		mats.printer = prmat;

		PRNT.envMap = env;

		const parseNode = (node) => {

			if(node.type == 'Mesh' || node.type == 'SkinnedMesh') {
				node.material = DEBUG_MAT;
				this.noHolos.push(node);

				if(node.parent && node.parent.type == "PerspectiveCamera") {
					return;
				}

				// apply materials
				const name = node.name.toLowerCase();

				if (name.indexOf('text_') > -1) {
					node.visible = false;
					// console.log('Found Text:', name);
					_3dTexts.push(node);
					// node.parent.remove(node);
					// console.log(node.parent);
					return;
				}

				if (name == "backdrop") {
					node.material = BACKDROP;
				}

				if (name == "ash_hologram") {
					node.material = HOLO;
					this.holos.push(node);
				}

				if ( name.indexOf('ash_hologram_shadow') > -1 ) {
					node.material = new MeshBasicMaterial({
						map: this.getTextureByPath('hologram/ash_shadow.ktx2'),
						opacity: 0.5,
						transparent: true
					});
				}

				if (name == "veronica_hologram") {
					node.material = holo2;
					this.holos.push(node);
				}

				if ( name.indexOf('veronica_hologram_shadow') > -1 ) {
					node.material = new MeshBasicMaterial({
						map: this.getTextureByPath('hologram/veronica_shadow.ktx2'),
						opacity: 0.5,
						transparent: true
					});
				}

				if (name.indexOf('cone') > -1) {
					node.material = CONE;
				}

				if (name.indexOf('base_unit') > -1) {
					node.material = holoBase;
				}

				if (name.indexOf('button_left') > -1) {
					node.material = holoBase;
					addIObject(node);

				}

				if (name.indexOf('button_right') > -1) {
					node.material = holoBase;
					addIObject(node);
				}

				if (name.indexOf('tv') > -1) {
					if ( name.indexOf('plane') > -1 ) {
						node.material = new MeshBasicMaterial({
							map: this.getTextureByPath('tv/ground_plane.ktx2'),
							transparent: true
						});
						// SHADOW_MAT.uniforms.tInput.value = this.getTextureByPath('tv/ground_plane.ktx2');
						node.position.y += .0025;
					}
					else node.material = tvmat;
				}

				if (name.indexOf('knob') > -1) {
					node.material = tvmat;
					this.knob = node;
				}

				if (name.indexOf('screen') > -1 ) {
					node.material = screenmat;
					// node.ge
					if(node.parent.name.toLowerCase().indexOf('tv') > -1) {
						// console.log(node.parent.name);
						node.getWorldPosition(this.cc.transitionPos);
						node.getWorldPosition(this.cc.transitionLookAt);
					}
				}

				if(name.indexOf('printer') > -1) {
					node.material = prmat;
				}

				if((name.indexOf('print01') > -1) ||
				(name.indexOf('print02') > -1) ||
				(name.indexOf('print03') > -1)) {

					// print 01
					node.geometry.computeBoundingBox();
					const mat = PRNT.clone();
					node.material = mat;
					node.frustumCulled = false;

					const i = parseInt(name.split('print0')[1]);
					mat.map = this.getTextureByPath(`printer/prints/AO_print0${i}.ktx2`);

					mat.onBeforeCompile = (shader) => {

						let vs = shader.vertexShader;
						vs = vs.replace(
							`#include <clipping_planes_pars_vertex>`,
							`#include <clipping_planes_pars_vertex>
							varying float vP;`,
						)
						vs = vs.replace(
							`#include <uv_vertex>`,
							`vec3 pos = position;
							float sp = smoothstep(-0.1, 0.5, pos.y);
							vP = sp;
							#include <uv_vertex>`,
						)

						let fs = shader.fragmentShader;
						fs = fs.replace(
							`#include <clipping_planes_pars_fragment>`,
							`#include <clipping_planes_pars_fragment>
							varying float vP;
							uniform float progress;`
						);
						fs = fs.replace(
							`#include <clipping_planes_fragment>`,
							`if(vP > progress) discard;
							#include <clipping_planes_fragment>`
						);

						shader.vertexShader = vs;
						shader.fragmentShader = fs;
						shader.uniforms.progress = {
							value: 0
						};
						node.shader = shader;
					}

					this.prints.push(node);
				}

				if(node.parent.name.toLowerCase() == "computer") {
					if(node.name.toLowerCase() == "shadow_plane") {
						node.material = new MeshBasicMaterial({
							map: this.getTextureByPath('printer/shadow_plane.ktx2'),
							transparent: true,
							opacity: 0.75
						});
						// node.position.y += .0025;
					} else {
						node.material = prmat;
					}
					if (name.indexOf('screen') > -1 ) {
						const mat = screenmat.clone();
						mat.map = this.computerVideoTex.texture,
						mat.emissiveMap = this.computerVideoTex.texture,
						mat.emissive.set("#ffffff");
						mats.printer_scr = mat;
						node.material = mat;
					}
					if (name.indexOf("button") > -1) {
						node.oY = node.position.y;
						addIObject(node);
					}
				}
			}
			else if(node.type == "PerspectiveCamera") {
				if(node.name.toLowerCase().indexOf('mobile') == -1) {
					// console.log("Found Desktop Camera:", node.name);
					this.cc.addTarget(node);
				} else {
					// console.log("Found Mobile Camera:", node.name);
					this.cc.addMobileTarget(node);
				}
			}

			for (const child of node.children) {
				parseNode(child);
			}
		}

		for (const node of gltf.scene.children) {
			parseNode(node);
		}

		// insert 3D texts
		if(this.page === 'home') {
			this.register3DTexts();
		}

		this.registerUIEvents();
		this.setHovers();

		// this.createVideoMap();

		super.onLoaded();
	}

	register3DTexts() {
		// return;

		for(let i=0;i<_3dTexts.length/2;i++) {
			const _3dText = new Text3D([_3dTexts[i*2], _3dTexts[i*2+1]], i);
			this.texts3D.push(_3dText);
		}

	}

	registerUIEvents () {
		document.addEventListener('mousemove', (event)=>{
			if(isTouchDevice()) return;
			if(!this.active) return;
			const x = -1 + 2*event.clientX/window.innerWidth;
			const y =  1 - 2*event.clientY/window.innerHeight;
			this.mouseTarget.set(x,y);
			hover3D(this.mouseTarget, this.cc.camera);
		});

		const down = (event:MouseEvent|TouchEvent) => {
			const cio = down3D();

			if(cio != null) {
				event.preventDefault();
				event.stopPropagation();
				if(cio.name.toLowerCase() == "right_button") {
					if(this.printing) return;
					PRTSCR.uniforms.selected.value = (PRTSCR.uniforms.selected.value+1)%3;
				} else if(cio.name.toLowerCase() == "left_button") {
					if(this.printing) return;
					PRTSCR.uniforms.selected.value = PRTSCR.uniforms.selected.value-1<0 ? 2 : PRTSCR.uniforms.selected.value-1;
				} else if(cio.name.toLowerCase() == "tick_button") {
					if(this.printing) return;

					if(isMobile()){
						SCROLLER.scrollTo('printer-scroll-to');
					}

					this.printing = true;
					for(const prnt of this.prints) prnt.shader.uniforms.progress.value = 0;
					mats.lastPrinted = PRTSCR.uniforms.selected.value;
					this.currentPrint = this.prints[PRTSCR.uniforms.selected.value];
					this.currentPrint.material.color.set(PALETTES.getRandomBase());
					PRTSCR.uniforms.selected.value = 3;
					this.action.play();
				} else if(cio.name.toLowerCase() == "cross_button") {
					this.resetPrinting(true);
				}
			}
		}

		document.addEventListener('mousedown', (event)=>{
			if(!this.active) return;
			down(event);
		});

		document.addEventListener('mouseup', (event)=>{
			if(!this.active) return;
			up3D();
		});

		document.addEventListener('touchstart', (e) => {
			const x = -1 + 2*e.touches[0].clientX/window.innerWidth;
			const y =  1 - 2*e.touches[0].clientY/viewport.height;

			const offset = 1 / (viewport.height-window.innerHeight);

			this.mouseTarget.set(x,y-offset);
			hover3D(this.mouseTarget, this.cc.camera)
			down(e);
		});

		document.addEventListener('touchend', (event)=>{
			if(!this.active) return;
			up3D();
		});
	}

	private resetPrinting(userReset:boolean=false) {
		if(!this.printing) return;
		this.printing = false;
		PRTSCR.uniforms.selected.value = mats.lastPrinted;
		this.action.stop();
	}

	activate(): void {
		super.activate();
		transition.value = 0;
		this.cc.updateSections();
	}

	onResize(width: number, height: number) {
		super.onResize(width, height);
		this.cc.onResize(width, height);
		this.holoFbo.setSize(width, height);
		this.composer.setSize(width, height);
		this.blur.setSize(width, height);

		this.renderer.getViewport(viewport);

		for (const t3d of this.texts3D) {
			t3d.updateMatrix();
		}
	}

	getHeight() {
		return isMobile() || isIpad() ? window.screen.availHeight : window.innerHeight;
	}

	update() {
		super.update();
		if (!this._loaded) return;


		const active = this.active;
		this.active = this.page === 'home';

		if(!active && this.active){
			this.activate();
		}

		if(active && !this.active) {
			// if(transition.value != 0){
				transition.active = false;
				transition.value = 0;
				this.removeHighlight(true);
			// }

			for (const t3d of this.texts3D) {
				t3d.hide();
			}

			this.cc.leave();

			for(const k in this.videoMap) {
				if(_isMobile) break;
				const vt = this.videoMap[k];
				vt.image.style.display = 'none';
			}
		}

		// Hide 3D Texts on page change
		if(!this.active) return;

		if(!this.texts3D.length) this.register3DTexts();
		for (const t3d of this.texts3D) {
			t3d.show();
		}

		const dt: number = this.clock.getDelta();
		const t: number = this.clock.getElapsedTime();

		const pulse = MathUtils.smoothstep(-.5, 1, Math.sin(t * 1.5));

		if(mats.printer) {
			mats.printer.emissiveIntensity = pulse;
			mats.printer_scr.emissiveIntensity = .3 + .3 * pulse;
		}

		this.mixer.update(dt)

		for(let i=0,len=this.holos.length;i<len;i++) {
			this.holos[i].material.uniforms.time.value = t;
		}

		const u = TV_SCREEN.uniforms;
		u.time.value = t;
		u.pTrans.value = transition.value;
		this.cc.transition = transition.value;

		PRTSCR.uniforms.time.value = t;

		// TV video
		if(u.video.value != null && u.videoP.value > 0) {
			this.vhs.update(u.video.value.image, t, _isMobile);
			// u.video.value.update();
		}

		// HOLO.uniforms.time.value = t;
		CONE.uniforms.time.value = t;
		this.comp.shader.uniforms.t.value = t;

		this.offset = MathUtils.lerp(this.offset, t > 0.5 ? 1 : 0, .036);

		const offset = this.offset;


		const alpha = this.cc.updateTarget(offset) - offset;
		const r = BACKDROP.uniforms.colorRamp;
		// console.log(alpha);

		if(alpha < 1) {
			r.value = MathUtils.smoothstep(0.5, 1, alpha) * 1/3;
		} else if (alpha < 2) {
			r.value = 1/3 + MathUtils.smoothstep(1, 2, alpha) * 1/3;
		} else {
			r.value = 2/3 + MathUtils.smoothstep(4, 5, alpha) * 1/3;
		}

		this.mouse.lerp(this.mouseTarget, MOUSE_EASING);
		this.cc.update(this.mouse);

		this.cc.blockMouse = alpha > 7 - offset;

		if(this.printing && this.currentPrint.shader) {
			const u = this.currentPrint.shader.uniforms;
			// console.log(this.action.time);
			let p = 0;
			if(this.action.time < 8) {
				p = .05 + .2 * MathUtils.smoothstep(2, 8, this.action.time);
			} else if(this.action.time < 24) {
				p = .25 + .5 * MathUtils.smoothstep(8, 23, this.action.time);
			} /* else {
				p = 1;
				console.log('reset printer');
				this.resetPrinting();
			} */
			u.progress.value = p;
		}

		if(SHOW_WHOLE_SCENE) {
			this.cc.camera.position.set(0, 5, 20);
			this.cc.camera.lookAt(this.scene.position);
		}

		// rotate holograms
		if(holoI.node != null) {
			holoI.node.rotateY(holoI.left ? -.01 : .01);
			holoI.cone.rotation.y = holoI.node.rotation.y;
		}
	}

	render() {
		if (!this.loaded || !this.active) return;
		this.renderer.autoClear = true;
		for(const el of this.noHolos) el.visible = false;
		for(const el of this.holos) el.visible = true;
		this.renderer.setClearColor(0x000000, 1.0);
		FboUtils.renderToFbo(this.screenFbo, this.renderer, TV_SCREEN);
		FboUtils.renderToFbo(this.computerVideoTex, this.renderer, PRTSCR);
		TV_SCR_MAT.map = this.screenFbo.texture;
		this.renderer.setClearColor(0x4175e7, 0.0);
		this.renderer.setRenderTarget(this.holoFbo);
		this.renderer.render(this.scene, this.cc.camera);
		this.blur.renderInternal(this.renderer);

		for(const el of this.noHolos) el.visible = true;
		for(const el of this.holos) el.visible = false;
		this.renderer.setRenderTarget(null);
		this.renderer.render(this.scene, this.cc.camera);
		this.renderer.autoClear = false;
		this.renderer.clearDepth();
		this.renderer.setClearColor(0x4175e7, 0.0);
		this.comp.shader.uniforms.tBlur.value = this.blur.texture;
		this.composer.pass(this.renderer, this.comp, true);

		css3D.render(this.cc.camera);
	}

	dispose () {
		super.dispose();
		this.holoFbo.dispose();
		this._assets.destroy();
	}

	transitionIn(resolve: any): void {
		this.removeHighlight();
		super.transitionIn(resolve);
	}

	transitionOut(resolve){
		// Only do transitionOut when going from home -> project
		if(CURRENT_PAGE.slug !== 'project'){
			resolve();
			return;
		}

		// Quick fix to fade html right away
		setTimeout(() => {
			resolve();
		}, 800);

		transition.active = true;
		gsap.killTweensOf(transition);
		transition.value = 0;

		gsap.to(transition, {
			duration: 1.9,
			value: 1,
			ease: "power2.inOut",
			onComplete: () => {
				transition.active = false;
			}
		});

		for(const k in this.videoMap) {
			const vt = this.videoMap[k];
			vt.image.style.display = 'none';
		}

	}
}