import { gsap } from 'gsap';
import CanvasDefaultAnimation from '../CanvasDefaultAnimation';

import {
  BufferAttribute,
  BufferGeometry, Mesh, ShaderMaterial
} from 'three';
import fragmentShader from '../../../glsl/background.frag';
import vertexShader from '../../../glsl/background.vert';
import { getColor } from '../../../partials/Palettes';
import { SCROLL_POSITION } from '../../../partials/Scroller';

export default class BackgroundTriangle extends CanvasDefaultAnimation {
  constructor(el, opts) {
    super(el, opts);

    this.side = opts.left;

    this.progress = {
      value: -0.5
    }

    this.mesh = null;

    this.positions = null;
    this.previousPosition = 0;
    this.positionUpdate = 0;

    this.bgPositions = null;

    this.addBackground();

    window.addEventListener('palette-change', () => {
      this.mesh.material.uniforms.color.value = getColor();
    });

  }

  addBackground() {
    const material = new ShaderMaterial({
      uniforms: {
        side: { value: this.side },
        time: { value: 0 },
        speed: { value: 0 },
        color: { value: getColor() },
      },
      transparent: false,
      fragmentShader: fragmentShader,
      vertexShader: vertexShader,
      wireframe: false,
    });


    const geometry = new BufferGeometry();
    this.positions = new Float32Array(3 * 3);

    if (this.side) {
      this.positions[0] = 0.5;
      this.positions[1] = -0.5;

      // Aquest
      this.positions[3] = 0.5;
      this.positions[4] = 0.5;
      this.positionUpdate = 4;

      this.positions[6] = -0.5;
      this.positions[7] = -0.5;
    } else {
      // Aquest
      this.positions[0] = -0.5;
      this.positions[1] = 0.5;
      this.positionUpdate = 1;

      this.positions[3] = -0.5;
      this.positions[4] = -0.5;

      this.positions[6] = 0.5;
      this.positions[7] = -0.5;
    }

    this.bgPositions = new BufferAttribute(this.positions, 3);

    geometry.setAttribute('position', this.bgPositions);

    const mesh = new Mesh(geometry, material);

    this.scene.add(mesh);

    this.mesh = mesh;
    this.mesh.position.z = -0.01;
  }

  getPosition(){
    super.getPosition();

    if(!this.mesh) return;

    this.mesh.scale.set(
      this.rect.width,
      this.rect.height,
      1
    );

    this.mesh.material.uniforms.color.value = getColor();
  }

  show(){
    if(this.visible) return;
    super.show();

    gsap.to(this.progress, {
      value: 0.5,
      ease: 'default',
      duration: 3,
    })
  }

  disable() {
    super.disable();
    if(!this.mesh) return
    this.mesh.geometry.dispose();
    this.mesh.material.dispose();
    this.scene.remove(this.mesh);
  }

  setPosition() {
    if(!this.mesh) return;
    this.mesh.position.y = SCROLL_POSITION.current - this.top + this.window.h * .5 - this.rect.height * .5
    this.mesh.position.x = this.rect.left - this.window.w * .5 + this.rect.width * .5;
  }

  update() {
    if(!this.visible) return;
    this.setPosition();

    this.mesh.geometry.attributes.position.array[this.positionUpdate] = this.progress.value;
    this.mesh.geometry.attributes.position.needsUpdate = true;
  }
}
