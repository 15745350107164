import { CanvasTexture, VideoTexture } from "three";
import { LinearFilter, Texture } from "three";

export default class CustomVideoTexture extends CanvasTexture {
	// texture:CanvasTexture;
	// image:HTMLVideoElement;
	video:HTMLVideoElement;
	// isVideoTexture:boolean = true;
	ctx:CanvasRenderingContext2D;
	constructor(video:HTMLVideoElement) {
		// super(video);
		const canvas = document.createElement('canvas');
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;
		super(canvas);
		video.crossOrigin = 'anonymous';
		this.ctx = canvas.getContext('2d');

		this.ctx.fillStyle = "#000";
		this.ctx.fillRect(0, 0, canvas.width, canvas.height);

		this.video = video;
	}

	update() {
		if(this.video.readyState > 3 && !this.video.paused) {
			this.ctx.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);
			this.needsUpdate = true;
		}
	}
	
	static genVideoTexture(url:string, callback?:Function, videoEl?:HTMLVideoElement) {
		const video = videoEl === undefined ? document.createElement('video') : videoEl;
		video.crossOrigin = 'anonymous';
		video.muted = true;
		video.autoplay = true;
		video.setAttribute('playsinline', 'playsinline');
		video.setAttribute('webkit-playsinline', 'webkit-playsinline');
		video.src = url;
		video.loop = true;
		// video.style.opacity = '0';
		if(videoEl === undefined) document.querySelector('.custom-video-texture__wrapper').appendChild(video);
		video.onerror = (error) =>{
			console.warn(`${url} / Video load error. Retrying...`);
			video.src = url;
		}

		const finish = () => {			

			if(callback) {
				// callback(new CustomVideoTexture(video));
				video.pause();
				callback(new VideoTexture(video));
				// document.body.removeChild(video);
			}
		}
		
		let l = false;
		let loaded = () => {
			if (!l && video.readyState >= 3) {
				return finish();
			}
			// requestAnimationFrame(loaded);
			window.setTimeout(loaded, 100);
		};

		loaded();
	}
}