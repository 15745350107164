import { Logo } from './Logo';
import { Navigation } from './Navigation';

export class Header {
  constructor() {
    this.header = document.querySelector('.header___wrapper');
    this.logo = new Logo(this.header.querySelector('.header___logo'));
    this.nav = new Navigation(document.querySelector('.navigation___wrapper'));
  }

  show(){
    this.nav.show();
  }

  close(){
    this.nav.close();
  }

  onResize() {
    this.logo.onResize();
  }
}
