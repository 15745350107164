import { webgl } from '@jocabola/utils';
import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase.js'
import { Cursor } from "../partials/Cursor";
import { Header } from "../partials/header/Header";
import { Scroller } from "../partials/Scroller";
import { SiteLoader } from "../partials/SiteLoader";
import { Palettes } from '../partials/Palettes';

gsap.registerPlugin(CustomEase);
gsap.config({
	force3D: true
})

/**
 * DEV_MODE is injected by esbuild
 */
export const IS_DEV_MODE = DEV_MODE;

export const ASSETS_URL = IS_DEV_MODE ? '/assets/' : 'https://d1cm9dpfblibng.cloudfront.net/';
// export const ASSETS_URL = 'https://d1cm9dpfblibng.cloudfront.net/';
export const GLB_EXT = IS_DEV_MODE ? 'glb' : 'glb.gz';



/**
 * Debug - fallback
 */
export const DEBUG = true;
export const FALLBACK = !webgl;
if(!FALLBACK){
	document.querySelectorAll('[fallback]').forEach(el => {
		el.remove();
	})
} else {
	document.querySelector('body').classList.add('fallback__active')
}


/**
 * Eases
 */
CustomEase.create('default', '0.7, 0.4, 0, 1');
export const ANIMATIONS_DURATION = {
	fast: .4,
	normal: .6,
	slow: .8
}


/**
 * Pages
 */
export const PAGES = new Map();
export const CURRENT_PAGE = {
	slug: ''
};

/**
 * Cursor
 */
export const CURSOR_POSITION = {
	x: window.innerWidth * .5,
	y: window.innerHeight * .5,
	lastX: window.innerWidth * .5,
	lastY: window.innerHeight * .5
}
export const CURSOR_EASE = 0.1;
export const CURSOR = new Cursor();

/**
 * Scroll
 */
export const SCROLLER = new Scroller(0.05, 0.1);
SCROLLER.pause();

export const PALETTES = new Palettes();

/**
 * HEADER
 */
export const HEADER = new Header();

/**
 * LOADER
 */
export const SITE_LOADER = new SiteLoader();
if(FALLBACK) SITE_LOADER.update(1);

