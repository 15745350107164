import {
  LinearFilter,
  LinearMipMapLinearFilter,
  Mesh, PlaneGeometry, RGBAFormat, ShaderMaterial, TextureLoader, Vector2
} from 'three';
import CustomVideoTexture from '../../../glLayer/gfx/CustomVideoTexture';
import fragmentShader from '../../../glsl/image.frag';
import vertexShader from '../../../glsl/image.vert';
import CanvasDefaultAnimation from '../CanvasDefaultAnimation';

import { Random } from '@jocabola/math';
import { isTouchDevice } from '@jocabola/utils';
import { gsap } from 'gsap';
import { SCROLL_POSITION } from '../../../partials/Scroller';

const GREYSCALE_OFFSET = .3;
const textureLoader = new TextureLoader();

function hasAudio (video) {
  return video.mozHasAudio ||
  Boolean(video.webkitAudioDecodedByteCount) ||
  Boolean(video.audioTracks && video.audioTracks.length);
}

export default class Images extends CanvasDefaultAnimation {
  constructor(el, opts) {
    super(el, opts);

    this.isCover = this.dom.hasAttribute('canvas-scroll-anim-type');

    this.items = [];
    this.materials = [];
    this.domEls = this.dom.querySelectorAll('img, video');

    this.material = new ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        speed: { value: 0 },
        resolution: {
          value: new Vector2(0, 0),
        },
        uImage: { value: null },
        greyScale: { value: 1 },
        cover: { value: this.isCover },
        mouse: { value: new Vector2(0, 0) },
        radius: { value: 0 }
      },
      transparent: false,
      fragmentShader: fragmentShader,
      vertexShader: vertexShader,
      wireframe: false,
    });

    for (const el of this.domEls) {
      const item = {
        dom: el,
        texture: undefined,
        mesh: null,
        isVideo: false,
        greyscale: true
      };
      this.items.push(item);
    }

    this.loadItems();
    this.getPosition();
  }

  loadItems () {
    for (const item of this.items) {
      this.loadItem(item);
    }
  }

  loadItem(item) {

    if (item.dom.tagName === 'IMG') {
      textureLoader.load(item.dom.getAttribute('src'), (texture) => {
        this.addMesh(item, texture);
        texture.minFilter = LinearMipMapLinearFilter;
        //custom mipmap
        /* const can = document.createElement('canvas');
        const ir = texture.image.width / texture.image.height;
        can.width = 128;
        can.height = 128 / ir;
        const ctx = can.getContext('2d');
        ctx.drawImage(
          texture.image,
          0, 0,
          texture.image.width,
          texture.image.height,
          0, 0, can.width, can.height
        );
        texture.mipmaps[0] = texture.image;
        texture.mipmaps[1] = can; */
        // console.log(texture.mipmaps);
        this.needsUpdate = true;
      });
    }
    else {
      const vsrc = item.dom.dataset.src;
      CustomVideoTexture.genVideoTexture(vsrc, (texture)=>{
        item.isVideo = true;
        this.addMesh(item, texture);

        this.needsUpdate = true;
        if(!hasAudio(item.dom)) item.dom.parentNode.classList.add('no-audio');

      }, item.dom);


      item.dom.addEventListener('click', () => {
        item.dom.muted = !item.dom.muted;

        if(!item.dom.muted) { 
          item.dom.parentNode.classList.add('not-muted')
        } else item.dom.parentNode.classList.remove('not-muted')

      })
    }
  }

  addMesh(item, texture) {
    const bounds = item.dom.getBoundingClientRect();

    const geometry = new PlaneGeometry(1, 1, 15, 15);
    // texture.needsUpdate = true;

    const material = this.material.clone();
    material.transparent = texture.format === RGBAFormat;
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;

    this.materials.push(material);
    material.uniforms.uImage.value = texture;
    material.uniforms.uImage.value = texture;

    const mesh = new Mesh(geometry, material);

    this.scene.add(mesh);

    item.mesh = mesh;
    item.bounds = {
      top: bounds.top,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height,
      parallax: item.isVideo || this.dom.classList.contains('centered-image') || isTouchDevice() ? 1 : Random.randf(0.8, 1.2)
    };

    item.mesh.material.uniforms.resolution.value.x = item.bounds.width;
    item.mesh.material.uniforms.resolution.value.y = item.bounds.height;
    item.texture = texture;
  }

  // ------------------- SHOW - HIDE
  show() {
    if(this.visible) return;
    super.show();
    this.clock.stop();
    this.clock.startTime = this.clock.elapsedTime;
    this.clock.start();
    this.time = 0;
  }

  hide() {
    if(!this.visible) return;
    super.hide();
    this.clock.stop();

    for(let i = 0, len = this.items.length; i<len; i++){
      this.updateVideo(this.items[i])
    }
  }

  disable() {
    super.disable();

    for (const item of this.items) {
      if(!item.mesh) continue;
      item.mesh.geometry.dispose();
      item.texture.dispose();
      item.mesh.material.dispose();
      this.scene.remove(item.mesh);
    }
    this.items = [];
  }

  // ----------------- RESIZE
  getPosition() {
    super.getPosition();

    if(!this.items) return;
    if(this.items.length === 0) return;

    for(let i = 0, len = this.items.length; i<len; i++){
      if(!this.items[i].mesh) continue;

      const item = this.items[i];
      const rect = item.dom.getBoundingClientRect();

      item.bounds.width = rect.width;
      item.bounds.height = rect.height;
      item.bounds.top = rect.top + SCROLL_POSITION.target - this.window.h * .5 + item.bounds.height * .5;
      item.bounds.left = rect.left - this.window.w * .5 + item.bounds.width * .5;

      item.mesh.material.uniforms.resolution.value.x = item.bounds.width;
      item.mesh.material.uniforms.resolution.value.y = item.bounds.height;
      item.mesh.scale.set(item.bounds.width, item.bounds.height, 1);
    }
  }

  onResize() {
    this.getPosition();
    for(let i = 0, len = this.items.length; i<len; i++){
      this.setPosition(this.items[i]);
    }
  }

  // ----------------- UPDATE
  setPosition(item) {

    if(!item.mesh) return;

    item.mesh.position.y = SCROLL_POSITION.current - item.bounds.top;
    item.mesh.position.x = item.bounds.left;

    item.mesh.position.y = item.mesh.position.y * item.bounds.parallax;
  }

  updateMaterial(item) {

    const mat = item.mesh.material;
    mat.uniforms.time.value = this.time;
    mat.uniforms.speed.value = this.delta;

  }

  updateGrayscale(item){

    const mat = item.mesh.material;
    const scroll = SCROLL_POSITION.current;
    const offset = this.window.h * GREYSCALE_OFFSET;

    if(this.top - this.window.h < scroll - offset && this.bottom > scroll + offset){
      if(item.greyscale){
        item.greyscale = false;
        gsap.killTweensOf(mat.uniforms.greyScale);
        gsap.to(mat.uniforms.greyScale, {
          value: 0,
          ease: 'default',
          duration: 0.3,
        })
      }

    } else {
      if(!item.greyscale){
        item.greyscale = true;
        gsap.killTweensOf(mat.uniforms.greyScale);
        gsap.to(mat.uniforms.greyScale, {
          value: 1,
          ease: 'default',
          duration: 0.3,
        })
      }
    }
  }

  updateVideo(item){
    if(!item.isVideo) return;

    const video = item.texture.image;

    if(this.visible && video.paused) {
      // console.log('Play');
      video.play();
      video.classList.add('video__playing');
    }

    if(!this.visible && !video.paused){
      // console.log('Pause');
      video.pause();
      video.muted = true;
      item.dom.parentNode.classList.remove('not-muted')
      video.classList.remove('video__playing');
    }

    // item.texture.update();

  }

  update() {

    if(!this.visible) return;

    this.time = this.clock.getElapsedTime();

    for(let i = 0, len = this.items.length; i<len; i++){

      if(!this.items[i].mesh) continue;

      this.setPosition(this.items[i]);
      this.updateMaterial(this.items[i]);
      this.updateGrayscale(this.items[i]);
      this.updateVideo(this.items[i]);

    }
  }

}
