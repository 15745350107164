import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText.js';
import DefaultAnimation from '../../DefaultAnimation';

gsap.registerPlugin(SplitText);


export default class TextTitle extends DefaultAnimation {
  constructor(el) {
    super(el);
    this.trigger = 0.5;

    // Primer intenta agafar directament els que tenen l'attr
    this.items = this.dom.querySelectorAll('[title-item]');

    // Si no n'hi ha cap pilla només títols
    if(this.items.length === 0){
      this.items = this.dom.querySelectorAll('h1, h2, h3, h4, h5, h6');
    }

    // Si tampoc hi ha títols llavors si que pilla la 'p'
    if(this.items.length === 0){
      this.items = this.dom.querySelectorAll('p');
    }

    this.split = null;

    this.createTl();
  }

  onResize(){
    super.onResize();

    this.split?.revert();
    this.tl?.kill();
    this.createTl();

  }

  createTl(){
    this.split = new SplitText(this.items, { type: "words, chars" });

    for(const word of this.split.words){
      word.style.overflow = 'hidden';
      word.style.lineHeight = '1.5em';
      word.style.marginBottom = '-0.5em';
    }

    gsap.set(this.split.chars, { yPercent: 100 });

    this.tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        // this.split.revert();
      }
    });

    this.tl
      .to(this.split.chars, {
        yPercent: 0,
        stagger: {
          each: 0.02
        },
        duration: 1,
        ease: 'default'
      });
  }

  show() {
    if(this.visible) return;
    super.show();
    if(this.tl.progress() > 0) return;
    this.tl.play();
  }

  hide(){
    if(!this.visible) return;
    super.hide();
    if(this.tl.progress() === 1){
      // this.split?.revert();
    }
  }
}
