import DefaultAnimation from '../../DefaultAnimation';

export default class Footer extends DefaultAnimation {
  constructor(el) {
    super(el);

    // Texts
    this.texts = this.dom.querySelectorAll('[footer-item]');
  }

  show() {
    if(this.visible) return;
    super.show();
    for(const item of this.texts) item.classList.add('active')
  }

  hide(){
    if(!this.visible) return;
    super.hide();
    for(const item of this.texts) item.classList.remove('active')
  }
}
