import { gsap } from 'gsap';
import { ANIMATIONS_DURATION, PALETTES } from '../core/Global';

const dom = document.querySelector('.site__loader');
const text = dom.querySelector('p');
const bar = dom.querySelector('.loader__bar');
const dots = dom.querySelectorAll('span');
const fill = dom.querySelector('.loader__fill') as HTMLElement;


export class SiteLoader {
	complete:boolean = false;
	dotsPlaying:boolean = false;
	barTl:gsap.core.Timeline;
	constructor(){

		const randomColor = PALETTES.getRandomColor();
		document.documentElement.style.setProperty(
			'--loader',
			randomColor
		);

		const bars = dom.querySelectorAll('svg rect');
		this.barTl = gsap.timeline({paused: true});
		this.barTl.to(bars, {
			autoAlpha: 1,
			stagger: 0.1,
			duration: 0
		})

		this.show();
	}

	show(){
		gsap.to([text, bar], {
			autoAlpha: 1,
			delay: 0.2,
			duration: ANIMATIONS_DURATION.slow,
			ease: 'default'
		})
	}


	hide(){

		gsap.killTweensOf(dots);
		gsap.to(dots, {
			autoAlpha: 0,
			duration: 0.2
		})

		gsap.to(dom, {
			delay: 0.5,
			autoAlpha: 0,
			duration: ANIMATIONS_DURATION.slow,
			ease: 'default',
			onComplete: () => {
				dom.remove();
				document.querySelector('body').classList.add('site__loaded');
				document.querySelector('body').classList.remove('site__loading');
			}
		})
	}

	update(p){
		if(this.complete) return;

		if(!this.dotsPlaying){
			this.dotsPlaying = true;

			const tl = gsap.timeline({ repeat: -1, repeatDelay: 0.5, paused: true })

			tl.to(dots, {
				stagger: {
					each: 0.1,
					amount: 1,
				},
				autoAlpha: 1,
				duration: 0.1,
			})
			.to(dots, {
				delay: 0.2,
				autoAlpha: 0,
				duration: 0.1
			})

			tl.timeScale(1.8);
			tl.play();

		}

		// Loading bar
		const percent = Math.round(p * 100);

		this.barTl.progress(p)

		if(percent >= 100) {
			this.complete = true;
			this.hide();
		}
	}
}