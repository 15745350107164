import { Color, ShaderMaterial, PlaneGeometry, Mesh } from 'three';
import CanvasDefaultAnimation from '../CanvasDefaultAnimation';
import vertexShader from '../../../glsl/background.vert';
import fragmentShader from '../../../glsl/background.frag';
import { getColor } from '../../../partials/Palettes';


export default class Background extends CanvasDefaultAnimation {
  constructor(el, opts) {
    super(el, opts);

    this.createBackground();

    window.addEventListener('palette-change', () => {
      this.mesh.material.uniforms.color.value = getColor();
    });
    
  }

  createBackground() {
    
    const material = new ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        speed: { value: 0 },
        color: { value: getColor() },
      },
      transparent: false,
      fragmentShader: fragmentShader,
      vertexShader: vertexShader,
      wireframe: false,
    });

    const geometry = new PlaneGeometry(1, 1, 50, 50);

    const mesh = new Mesh(geometry, material);

    this.scene.add(mesh);

    this.mesh = mesh;
    this.mesh.position.z = -0.01;

    this.needsUpdate = true;
  }

  getPosition(){
    super.getPosition();
    
    this.mesh?.scale.set(
      this.rect.width,
      this.rect.height,
      1
    );
  }

  disable() {
    super.disable();
    this.mesh.geometry.dispose();
    this.mesh.material.dispose();
    this.scene.remove(this.mesh);
  }

  update() {    
    if(!this.visible) return;


    this.setPosition();
    this.time += 0.05;
  }
}
