import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin.js';
import { ANIMATIONS_DURATION } from '../../core/Global';
gsap.registerPlugin(DrawSVGPlugin);

export class Burguer {
  constructor(dom) {
    this.dom = dom;

    this.burg = this.dom.querySelector('.burg');
    this.times = this.dom.querySelector('.times');

    this.tl = null;

    this.setup();
  }

  setup() {
    const burgLines = this.burg.querySelectorAll('line');
    const timesLines = this.times.querySelectorAll('line');

    gsap.set(timesLines, {
      drawSVG: '0% 0%',
    });
    gsap.set(this.times, {
      autoAlpha: 1,
    });

    this.tl = gsap.timeline({
      paused: true,
      defaults: { duration: ANIMATIONS_DURATION.fast, ease: 'default' },
    });

    this.tl
      .addLabel('start')
      .to(
        burgLines,
        {
          drawSVG: '0% 0%',
          stagger: {
            each: 0.3
          }
        },
        'start'
      )
      .to(
        timesLines,
        {
          drawSVG: '100% 0%',
          stagger: {
            each: 0.3
          }

        },
        'start+=0.8'
      );
  }

  open() {
    // this.tl.timeScale(1);
    this.tl.progress(0);
    this.tl.play();
  }
  close() {
    // this.tl.timeScale(1.5);
    this.tl.reverse();
  }
}
