import { Random } from '@jocabola/math';
import { gsap } from 'gsap';
import { Color } from 'three';
const urlFor = require('../../../utils/imageUrl');
import getPalettes from '../../../data/palettes';
import { ANIMATIONS_DURATION } from '../core/Global';

export const getColor = () => {
  let color = getComputedStyle(document.documentElement).getPropertyValue(
    '--color'
  );
  color = new Color(parseInt(color.replace('#', '0x'), 16));

  return color;
}

export class Palettes {
  constructor() {

    this.loaded = false;
    this.palettes = null;

    const palettesPromise = getPalettes();
    palettesPromise.then((result) => {
      this.palettes = result;
      this.loaded = true;
      this.update();
    });

  }

  update() {
    if(!this.loaded) return;

    const el = document.querySelector('[data-palette]');
    if (!!!el) return;
    const id = el.getAttribute('data-palette');
    el.removeAttribute('data-palette');

    this.projectButtons()

    const newPalette = this.palettes.find((x) => {
      return x._id === id;
    });

    const favicon = {
      180: urlFor(newPalette.favicon).width(180).format('png').url(),
      32: urlFor(newPalette.favicon).width(32).format('png').url(),
      16: urlFor(newPalette.favicon).width(16).format('png').url(),
    }
    document.querySelector('link[sizes="180x180"]').setAttribute('href', favicon[180]);
    document.querySelector('link[sizes="32x32"]').setAttribute('href', favicon[32]);
    document.querySelector('link[sizes="16x16"]').setAttribute('href', favicon[16]);
    document.querySelector('link[rel="shortcut icon"]').setAttribute('href', favicon[16]);

    const comp = getComputedStyle(document.documentElement);
    const currentColors = {
      color: comp.getPropertyValue('--color'),
      hover: comp.getPropertyValue('--hover'),
      menu: comp.getPropertyValue('--menu'),
      background: comp.getPropertyValue('--background'),
    };

    gsap.to(currentColors, {
      color: newPalette.color.hex,
      hover: newPalette.base.hex,
      menu: newPalette.menu.hex,
      background: newPalette.shade.hex,
      ease: 'default',
      duration: ANIMATIONS_DURATION.slow,
      onUpdate: () => {
        document.documentElement.style.setProperty(
          '--color',
          currentColors.color
        );
        document.documentElement.style.setProperty(
          '--hover',
          currentColors.hover
        );
        document.documentElement.style.setProperty(
          '--menu',
          currentColors.menu
        );
        document.documentElement.style.setProperty(
          '--background',
          currentColors.background
        );
      },
      onComplete: () => {
        const paletteChangeEvent = new CustomEvent('palette-change');
        window.dispatchEvent(paletteChangeEvent);
      },
    });
  }

  projectButtons(){
    const buttons = document.querySelectorAll('[button-palette]')
    if(!!!buttons) return

    for(const button of buttons){
      const id = button.getAttribute('button-palette')
      const palette = this.palettes.find((x) => {
        return x._id === id;
      });

      button.style.setProperty(
        '--color',
        palette.color.hex
      );
      button.style.setProperty(
        '--hover',
        palette.base.hex
      );
      button.style.setProperty(
        '--background',
        palette.shade.hex
      );
    }
  }

  getRandomBase(){

    const colors = [
      '#6081db',
      '#ffcb67',
      '#7965bc',
      '#744aff',
      '#69be7b',
      '#f34572',
    ];

    return colors[Random.randi(0, colors.length-1)];
  }

  getRandomColor(){

    const colors = [
      '#396ae3',
      '#ffb600',
      '#4c2cb5',
      '#63f',
      '#44ab61',
      '#ff3347',
    ]

    return colors[Random.randi(0, colors.length-1)];
  }
}
