import { MathUtils } from "@jocabola/math";
import { PerspectiveCamera, Vector2, Vector3 } from "three";
import { SCROLL_POSITION } from "../../../partials/Scroller";
import { TextsAnimationHandlers } from "./TextsAnimations";

const cubicInOut = (t:number) => {
return t < 0.5
    ? 4 * t * t * t
    : 0.5 * Math.pow(2 * t - 2, 3) + 1;
}

const EASING:number = .16;
let wh = window.innerHeight;
const V_ZERO = new Vector2();

export default class CameraController {
	camera: PerspectiveCamera;
	private targets:Array<PerspectiveCamera>;
	private lookAts:Array<Vector3>;
	private mTargets:Array<PerspectiveCamera>;
	private mLookAts:Array<Vector3>;
	private currentTarget:number = 0;
	private currentLookAt:Vector3;
	target:number = 0;
	transition:number = 0;
	transitionPos:Vector3 = new Vector3();
	transitionLookAt:Vector3 = new Vector3();
	alpha:number = 0;
	isPortrait:boolean = false;
	rects:Array<any> = [];
	blockMouse:boolean = false;
	mouseTarget:Vector2 = new Vector2();
	mouseAmp:number = 1;

	constructor (width:number, height:number) {
		this.camera = new PerspectiveCamera(75, width / height, .01, 5000);
		this.targets = [];
		this.lookAts = [];
		this.mTargets = [];
		this.mLookAts = [];
		this.currentLookAt = new Vector3();
	}

	addTarget(cam:PerspectiveCamera) {
		this.targets.push(cam);
		const tmp = new Vector3();
		cam.children[0].getWorldPosition(tmp);
		this.lookAts.push(tmp);
		// console.log(cam.fov);
		// cam.fov = 70;
	}

	addMobileTarget(cam:PerspectiveCamera) {
		this.mTargets.push(cam);
		// console.log(cam.fov);
		cam.fov = 60;
		const tmp = new Vector3();
		cam.children[0].getWorldPosition(tmp);
		this.mLookAts.push(tmp);
	}

	updateSections() {
		wh = window.innerHeight;
		const sections = document.querySelectorAll('.frontpage__section');
		this.isPortrait = window.innerWidth < window.innerHeight;
		let i = 0;

		const portraitCameras = [4]
		this.rects = [];
		for(const section of sections) {
			const r = section.getBoundingClientRect();
			this.rects.push({
				y: r.top + SCROLL_POSITION.target, 
				yh: r.top + r.height + SCROLL_POSITION.target,
				portrait: portraitCameras.includes(i)
			});
			
			i++;
		}			

		if(this.isPortrait) {
			document.body.classList.add('sections__portrait');
		} else {
			document.body.classList.remove('sections__portrait');
		}

	}

	updateTarget(offset:number=0): number {	

		const scroll = SCROLL_POSITION.current;		
		for(let i = 0, len = this.rects.length; i<len-1; i++) {
			const r = this.rects[i];
			if(scroll >= r.y && scroll <= r.yh) {				
				this.alpha = i + cubicInOut(MathUtils.smoothstep(r.yh - wh, r.yh, scroll));
			}
		}
		
		this.target = this.alpha;
		this.target += offset;

		// console.log(this.target);
		

		return this.alpha;
	}

	update(mouse:Vector2=null) {
		this.currentTarget = MathUtils.lerp(this.currentTarget, this.target, EASING);

		const i1 = Math.floor(this.currentTarget);
		const i2 = Math.ceil(this.currentTarget);

		const lA = this.isPortrait ? this.mLookAts : this.lookAts;
		const targets = this.isPortrait ? this.mTargets : this.targets;
		
		this.currentLookAt.copy(lA[i1]);

		if (i1 != i2) {
			const t = targets[i1];
			const t2 = targets[i2];
			const alpha = 1 - (i2-this.currentTarget)/(i2-i1);
			this.camera.position.copy(t.position).lerp(t2.position, alpha);
			// this.camera.quaternion.copy(t.quaternion).slerp(t2.quaternion, alpha);
			this.camera.fov = MathUtils.lerp(t.fov, t2.fov, alpha);
			this.currentLookAt.lerp(lA[i2], alpha)
		}
		else {
			const t = targets[i1];
			this.camera.position.copy(t.position);
			this.camera.fov = t.fov;
		}

		if((mouse!=null && this.transition < 1) && !this.blockMouse) {
			this.mouseTarget.copy(mouse);
		} else {
			this.mouseTarget.lerp(V_ZERO, .016);
		}

		const dx = (.75 - .5 * MathUtils.smoothstep(0, .5, this.currentTarget)) * this.mouseAmp;
		const dy = (.5 - .35 * MathUtils.smoothstep(0, .5, this.currentTarget)) * this.mouseAmp;
		// if(isPortrait) d *= -.2;
		this.camera.translateX((1-this.transition) * this.mouseTarget.x*dx);
		this.camera.translateY((1-this.transition) * this.mouseTarget.y*dy);

		this.camera.position.lerp(this.transitionPos, this.transition);
		this.currentLookAt.lerp(this.transitionLookAt, this.transition);

		this.camera.lookAt(this.currentLookAt);
		this.camera.updateMatrixWorld();

		this.camera.updateProjectionMatrix();

		TextsAnimationHandlers(this.alpha);
	}

	onResize(width:number, height:number) {
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();

		this.updateSections();
	}

	leave(){
		this.alpha = 0;
		this.target = 0;
		this.currentTarget = 0;
		this.transition = 0;
		this.updateTarget();
		this.update();
	}
}