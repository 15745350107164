import Stats from 'stats.js';
import { DEBUG_MAT } from '../glLayer/gfx/ShaderLib';
import { DEBUG, IS_DEV_MODE } from './Global';

export const stats = new Stats();

export const initStats = () => {
	if(IS_DEV_MODE) {
		stats.showPanel(0);
		document.body.appendChild(stats.dom);
	}
}
 
export const initDebugControls = () => {
	if(!DEBUG) return;
	window.addEventListener('keydown', (event) => {
		if(event.key == 'd') {
			DEBUG_MAT.visible = !DEBUG_MAT.visible;
		}
	});
}