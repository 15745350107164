import { SCROLL_POSITION } from '../../../partials/Scroller';
import DefaultAnimation from '../../DefaultAnimation';

export default class Sticky extends DefaultAnimation {
  constructor(el) {
    super(el);
    this.sticky = this.dom.querySelectorAll('[sticky-el]');
  }

  getPosition(){
    if(this.sticky){
      for(const s of this.sticky){
        s.style.transform = `translate3d(0, 0, 0)`;
      }
    }
    super.getPosition();
  }

  update() {
    if(!this.visible) return;
    if (this.sticky.length === 0) return;

    const scroll = SCROLL_POSITION.current;

    if (
      scroll >= this.top &&
      scroll <= this.bottom - this.window.h
    ) {
      const y = scroll - this.top;
      for (const s of this.sticky) {
        s.style.transform = `translate3d(0, ${y}px, 0)`;
      }
    }
  }
}
