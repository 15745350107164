import { MathUtils } from "@jocabola/math";
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText.js';

gsap.registerPlugin(SplitText);

let alpha = 0;

const AnimateText = (text:HTMLElement) => {
	const index = parseInt(text.getAttribute('data-slide'));
	const opacity = alpha < index ? MathUtils.map(alpha, index - 1, index, 0, 1) : MathUtils.map(alpha, index, index + 1, 1, 0);
	text.style.opacity = opacity.toString();
}

let coverAnimated = false;
const tl = gsap.timeline({ paused: true, onComplete: () => coverAnimated = true });
let firstSetup = false;
let chars = null;
const SetupTL = (text:HTMLElement) => {
	firstSetup = true;

	const split = text.querySelectorAll('h1, h2, h3, h4, h5, h6, p');
	const texts = new SplitText(split, { type: 'chars, words' });
	chars = texts.chars;

	gsap.set(chars, {
		yPercent: 120,
		autoAlpha: 0,
	})

	tl
		.to(chars, {
			delay: 1,
			stagger: {
				each: 0.01,
				from: 'start',
			},
			autoAlpha: 1,
			yPercent: 0,
			duration: 1,
			ease: 'default'
		})

}


const AnimateCover = (text:HTMLElement) => {

	if(!coverAnimated){
		tl.play();
	}

	const opacity = MathUtils.map(alpha, 0, 1, 1, 0);
	text.style.opacity = opacity.toString();

	if(alpha >= 0.999 && coverAnimated) {
		tl.pause();
		tl.progress(0)

		if(chars){
			gsap.set(chars, {
				yPercent: 120,
				autoAlpha: 0,
			})
			coverAnimated = false;
		}

	}
}

export const TextsAnimationHandlers = (newAlpha:number = 0) => {
	alpha = newAlpha;

	const texts = document.querySelectorAll('.text3D__item [data-slide]');

	for(const text of texts) {
		if(text.getAttribute('data-slide') !== 'cover') {
			AnimateText(text as HTMLElement);
		} else {
			if(!firstSetup) SetupTL(text as HTMLElement);
			AnimateCover(text as HTMLElement);
		}
	}

}