import { glInfo } from '@jocabola/gfx';
import { KTX2Asset } from '@jocabola/io';
import { isTouchDevice, webgl } from '@jocabola/utils';
import {
  Clock, PerspectiveCamera, Scene, WebGLRenderer
} from 'three';
import CanvasScrollAnimationsHandler from '../animations/canvasScrollAnimations/CanvasScrollAnimationsHandler';
import { initDebugControls } from '../core/dev';
import { ASSETS_URL } from '../core/Global';
import { SCROLL_POSITION } from '../partials/Scroller';
import glContact from './scenes/glContact';
import glHome from './scenes/glHome';
import { css3D } from './ui/css3D';

const MAX_PD = 1.5;

export class GlLayer {  
  constructor(container, opts = {}, onLoaded = null) {

    this.container = container;
    this.opts = opts;
    this.onLoaded = onLoaded;

    this.needsFallback = false;
    this.loaded = false;

    this.page = '';

    if (!webgl()) {
      this.needsFallback = true;
      return;
    }

    let w = window.innerWidth;
    let h = window.innerHeight;

    this.pixelRatio = Math.min(MAX_PD, window.devicePixelRatio || 1);

    this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
    KTX2Asset.setupBasisLoader(`/assets/js/basis/`, this.renderer);
    this.hdri = new KTX2Asset(`${ASSETS_URL}basis/hdri/studio.ktx2`);

    this.glInfo = new glInfo(this.renderer.getContext());
    window.glInfo = this.glInfo;
    
    if (this.glInfo.vendor.toLowerCase().indexOf("intel") > -1) this.pixelRatio = .8;

    this.renderer.setPixelRatio(this.pixelRatio);

    this.scenes = [];
    this.scenes.push(new glHome(w, h, this.renderer, this.hdri));
    this.scenes.push(new glContact(w, h, this.renderer, this.hdri));

    this.container.appendChild(this.renderer.domElement);

    this.emptyCamera = new PerspectiveCamera(50, w / h, 0.01, 1000);
    this.emptyScene = new Scene();
    this.emptyCamera.position.z = 2;

    // fix transparency if not rendering since the beginning
    this.renderer.render(this.emptyScene, this.emptyCamera);
    css3D.init(w,h);

    this.clock = new Clock();

    this.CanvasScrollAnimations = new CanvasScrollAnimationsHandler({
      container: this.container,
    });

    this.launch();
    
    this.onResize();

    this.loaded = true;
  }

  launch() {
    this.clock.start();
    initDebugControls();
  }

  get progress () {
    return (this.scenes[0].progress + this.scenes[1].progress)/2;
  }


  transitionIn(){
    return new Promise(resolve => {
     let active = false;
     for(const scene of this.scenes){
       if(scene.active) {
         scene.transitionIn(resolve);
         active = true;
       }
     }
     if(!active) resolve();
    })

  }
  transitionOut(){
     return new Promise(resolve => {
      let active = false;
      for(const scene of this.scenes){
        if(scene.active) {
          scene.transitionOut(resolve);
          active = true;
        }
      }
      if(!active) resolve();
    })
  }

  onResize() {
    let w = document.documentElement.clientWidth,
      h = document.documentElement.clientHeight;
    
    if(isTouchDevice()){

      const currentSize = this.renderer.domElement.getBoundingClientRect()
      const isPortrait = window.matchMedia("(orientation: portrait)");
      const offset = 100

      w = !isPortrait ? w + offset : w;
      h = isPortrait ? h + offset : h;

      if(w === Math.round(currentSize.width) && isPortrait || h === Math.round(currentSize.height) && !isPortrait) return;
    }

    this.container.style.width = `${w}px`
    this.container.style.height = `${h}px`
    this.renderer.setSize(w, h);

    for (const scene of this.scenes) {
      scene.onResize(w, h);
    }

    css3D.setSize(w,h);
    
    if (this.CanvasScrollAnimations) this.CanvasScrollAnimations.onResize();

  }

  disable(){
    if(this.CanvasScrollAnimations) this.CanvasScrollAnimations.disable();
  }

  update() {

    this.renderer.setClearColor(0xffffff, 0); 
    this.renderer.clear();
    this.renderer.autoClear = true;

    for (const scene of this.scenes) {
      scene.page = this.page;
      scene.scroll = SCROLL_POSITION.current;
      scene.update();
      if(!scene.active) continue;
      scene.render(this.renderer);
    }

    if (this.page === 'project' || this.page === 'education') {
      
      if(!this.CanvasScrollAnimations) return;

      this.CanvasScrollAnimations.update(SCROLL_POSITION.current);
      this.renderer.render(
        this.CanvasScrollAnimations.scene,
        this.CanvasScrollAnimations.camera
      );
    }
  }
}
