/**
 * VHS UI
 */

import { CanvasTexture } from "three";

const W = 512;
const H = 512;

const X = 50;
const Y = 60;
const FONT = '30px "VCR Mono"';

const textColors = [
	'#1d1d1d',
	'#fff',
]

const setContextShadow = (ctx:CanvasRenderingContext2D) => {
	ctx.shadowBlur = 1;
	ctx.shadowColor = "#666";
	ctx.shadowOffsetX = 2;
	ctx.shadowOffsetY = 2;
}

export class VHS {
	can:HTMLCanvasElement;
	texture:CanvasTexture;
	play:HTMLCanvasElement;

	constructor() {

		this.can = document.createElement('canvas');
		this.can.width = W;
		this.can.height = H;
	
		this.play = document.createElement('canvas');
		this.play.width = 250;
		this.play.height = 100;

		this.texture = new CanvasTexture(this.can);
		this.texture.flipY = false;

		this.setDefaultText(false);
	}

	setDefaultText(textColor:boolean){
		const ctx = this.play.getContext('2d');
		ctx.font = FONT;
		ctx.fillStyle = textColors[textColor ? 0 : 1];

		setContextShadow(ctx);
		ctx.fillText("PLAY", X, Y);
		ctx.beginPath();
		ctx.moveTo(X+80,Y-24);
		ctx.lineTo(X+105,Y-12);
		ctx.lineTo(X+80,Y-0);
		ctx.lineTo(X+80,Y-24);
		ctx.fill();

		const ctx2 = this.can.getContext('2d');
		ctx2.font = FONT;
		ctx2.fillStyle = textColors[textColor ? 0 : 1];
	}

	setData(year:string, type:string, textColor:boolean) {
		this.setDefaultText(textColor);

		const ctx = this.can.getContext('2d');
		
		ctx.clearRect(W-X-84, 0, 100, 100);
		ctx.clearRect(0, H-Y-5, W, 100);

		ctx.save();
		ctx.fillStyle = textColors[textColor ? 0 : 1];
		setContextShadow(ctx);
		ctx.fillText(`${year}`, W-X-80, Y);
		ctx.fillText(`${type.toUpperCase()}`, X, H-Y/2);
		ctx.restore();

		this.texture.needsUpdate = true;
	}

	update(video:HTMLVideoElement, time:number, isMobile:boolean=false) {
		const ctx = this.can.getContext('2d');
		
		if(isMobile) return;

		ctx.clearRect(0,0,this.play.width, this.play.height);
		if(time%.5<.25) ctx.drawImage(this.play,0,0);

		const t = video.currentTime;
		const sec = Math.floor(t);
		const r = Math.ceil((t-sec) * 60);
		let tstr = `${sec < 10 ? '0'+sec : sec}:${r < 10 ? '0'+r : r}`
		
		ctx.clearRect(W-X-84, W-Y, X+80, Y);
		ctx.save();
		setContextShadow(ctx);
		ctx.fillText(tstr, W-X-80, H-Y/2);
		ctx.restore();

		if(video.readyState > 2 && !video.paused) this.texture.needsUpdate = true;
	}
}