import { gsap } from 'gsap';

export const PAGE_TRANSITION_DURATION = 1;

export class Page {
  constructor () {
  }

  create(){
    this.loading = true;

    this.animationShow = gsap.timeline({ paused: true, onComplete: () => this.loading = false });
    this.animationHide = gsap.timeline({ paused: true });

    this.animationShow
      .to([document.getElementById('site__wrapper'), document.querySelectorAll('canvas')], {
          autoAlpha: 1,
          duration: PAGE_TRANSITION_DURATION,
          ease: 'power1.inOut',
        })

    this.animationHide
      .to([document.getElementById('site__wrapper'), document.querySelectorAll('canvas')], {
        autoAlpha: 0,
        duration: PAGE_TRANSITION_DURATION * .5,
        ease: 'power1.inOut',
      })
  }

  show (animation = this.animationShow) {

    return new Promise(resolve => {
      animation.call(() => {
        resolve()
      })
      animation.play()
    })
  }

  hide (animation = this.animationHide) {

    return new Promise(resolve => {
      animation.call(() => {
        resolve()
      })
      animation.play()
    })
  }
}