import { isTouchDevice } from '@jocabola/utils';
import { gsap } from 'gsap';
import { ANIMATIONS_DURATION } from '../../core/Global';


export class Logo {
  constructor(dom) {
    this.dom = dom;
    this.svg = this.dom.querySelector('svg');

    this.logoBack = this.svg.querySelector('.logo-back');
    this.logoMasked = this.svg.querySelector('.logo-masked');
    this.maskG = this.svg.querySelector('.logo-mask-g');
    this.mask = this.svg.querySelector('.logo-mask');

    this.disableHover = true;
    this.onResize();
    this.setup();

    this.binds();
  }

  onResize() {
    this.disableHover = isTouchDevice();
    this.rect = this.svg.getBoundingClientRect();
  }

  setup() {
    gsap.set(this.mask, { scale: 0, transformOrigin: '50% 50%' });
  }

  binds() {
    this.dom.addEventListener('mouseenter', this.enter.bind(this), true);
    this.dom.addEventListener('mouseleave', this.leave.bind(this), true);
  }

  enter(e) {
    if(isTouchDevice()) return;
    e.preventDefault();
    e.stopPropagation();

    gsap.to(this.mask, {
      scale: 1,
      duration: ANIMATIONS_DURATION.normal,
      ease: 'default',
      transformOrigin: '50% 50%',
    });
  }
  leave(e) {
    if(isTouchDevice()) return;
    e.preventDefault();
    e.stopPropagation();

    gsap.to(this.mask, {
      scale: 0,
      duration: ANIMATIONS_DURATION.normal,
      ease: 'default',
      transformOrigin: '50% 50%',
    });
  }
}
