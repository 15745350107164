import { MathUtils } from '@jocabola/math';
import { isTouchDevice } from '@jocabola/utils';

export const SCROLL_POSITION = {
	current: 0,
	previous: 0,
	target: 0,
	enabled: false,
}

export class Scroller {
  scroller: HTMLElement;
  content: HTMLElement;

  holder: HTMLElement;
  container: HTMLElement;

  paused: boolean = false;
  disabled: boolean = false;
  height: number = 0;

  ease: number;
  easeDevice: number;
  constructor(ease:number = 0.1, easeDevice: number = 0.1) {

    this.ease = ease;
    this.easeDevice = easeDevice;

    this.scroller = document.querySelector('[scroller]');
    this.holder = document.querySelector('[scroller-holder]');
    this.container = document.querySelector('[scroller-container]');
    this.content = this.scroller.querySelector('[scroller-content]');

    if ('scrollRestoration' in history) history.scrollRestoration = 'manual';

    window.addEventListener('wheel', (ev) => {
      this.scroller.style.pointerEvents = 'none';
      this.scroller.scrollTop += ev.deltaY;
    })

    window.addEventListener('touchstart', (ev) => {
      this.scroller.style.pointerEvents = 'all';
    })

    this.scroller.onscroll = () => {
      SCROLL_POSITION.target = this.paused ? SCROLL_POSITION.target : this.scroller.scrollTop;
    }

    this.scrollTo();
  }

  scrollTo(to:string = null){

    const scrollToElement = document.querySelector(`[scroll="${to}"]`);

    let newScrollPosition = scrollToElement ? scrollToElement.getBoundingClientRect().top + SCROLL_POSITION.target : 0;

    this.scroller.scrollTop = newScrollPosition;
    SCROLL_POSITION.target = newScrollPosition;
    SCROLL_POSITION.current = newScrollPosition;
    SCROLL_POSITION.previous = newScrollPosition;

  }

  pause(){
    this.paused = true;
  }

  resume(){
    this.paused = false;
  }

  disable(){
    if(this.disabled) return;
    this.disabled = true;

    this.content.style.transform = `translate3d(0, 0, 0)`;
    this.scroller.classList.add('disabled');
  }

  enable(){
    if(!this.disabled) return;
    this.disabled = false;
    this.scroller.classList.remove('disabled');
  }

  updateCheckHeight(){
    if(this.height === this.content.offsetHeight) return;
    this.height = this.content.offsetHeight;
    this.holder.style.height = `${this.height}px`;

    if(this.paused) return;
    window.dispatchEvent(new Event('resize'))
  }

  updateCheckExtremes(){

    if(SCROLL_POSITION.current <= 5) document.body.classList.add('scroll__top');
    else document.body.classList.remove('scroll__top');

    if(SCROLL_POSITION.current >= this.height - window.innerHeight * 1.5) document.body.classList.add('scroll__bottom');
    else document.body.classList.remove('scroll__bottom');

  }

  update() {

    SCROLL_POSITION.previous = SCROLL_POSITION.current;

    SCROLL_POSITION.current = MathUtils.lerp(SCROLL_POSITION.current, SCROLL_POSITION.target, this.disabled ? 0.3 : isTouchDevice() ? this.easeDevice : this.ease);

    if(this.paused) this.scroller.scrollTop = SCROLL_POSITION.target;

    this.updateCheckHeight();

    this.updateCheckExtremes();

    if(!this.disabled) this.content.style.transform = `translate3d(0, ${-SCROLL_POSITION.current.toFixed(5)}px, 0)`;

  }
}
