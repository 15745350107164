import { MathUtils } from "@jocabola/math";
import { SCROLL_POSITION } from "../partials/Scroller";

export default class ScrollAnimationsHandler {
  constructor() {
    this.items = [];
    this.needsUpdate = false;
    this.delta = 0;
  }

  addNew() {
    // Add all page animations
  }

  disable() {
    for (let i = 0, len = this.items.length; i<len; i++) this.items[i].disable();
    this.items = [];
  }

  onResize() {
    for (let i = 0, len = this.items.length; i<len; i++)  this.items[i].onResize();
  }

  update() {
    const offset = 0.025;
    const delta = MathUtils.clamp((SCROLL_POSITION.current - SCROLL_POSITION.previous) * offset, -1, 1);
    this.delta = MathUtils.lerp(this.delta, delta, .36);
    for (let i = 0, len = this.items.length; i<len; i++) {
      this.items[i].checkVisible();
      this.needsUpdate = this.items[i].needsUpdate ? true : this.needsUpdate;
      this.items[i].needsUpdate = false;
      this.items[i].delta = this.delta;
      this.items[i].update();
    }

    if(this.needsUpdate){
      this.needsUpdate = false;
      this.onResize();
    }

  }
}
