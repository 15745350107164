import { RenderPass } from "@jocabola/gfx";
import { Texture } from "three";
import { COMP_PASS } from "./ShaderLib";

export default class CompositionPass extends RenderPass {
	constructor(holo:Texture) {
		super();
		this.shader = COMP_PASS;
		COMP_PASS.uniforms.tHolo.value = holo;
	}
}