import { CURRENT_PAGE } from '../../core/Global';
import { Burguer } from './Burguer';
import { MenuItems } from './MenuItems';

export class Navigation {
  constructor(dom) {
    this.dom = dom;
    this.stat = false;

    this.changeInProgress = false;

    this.hamb = document.querySelector('.header___hamb');
    this.burguer = new Burguer(this.hamb);
    this.menuItems = new MenuItems(this.dom);

    this.binds();
  }

  show(){
    if(this.stat) return;

    this.stat = true;
    this.menuItems.activeItem(CURRENT_PAGE.slug);
    this.burguer.open();
    this.menuItems.show();
  }

  close(){
    if(!this.stat) return;
    this.stat = false;
    this.burguer.close();
    this.menuItems.hide();
  }

  binds() {
 
    window.addEventListener('keyup', (e) => {
      if(e.key === 'Escape' && this.stat) this.close();
    })

    const burguerClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.changeInProgress) {
        return;
      }

      this.changeInProgress = true;
      setTimeout(() => {
        this.changeInProgress = false;
      }, 2000);

      if (this.stat) {
        this.close();
      } else {
        this.show();
      }
    }

    this.hamb.addEventListener('click', (e) => burguerClick(e), true);
  }
}
