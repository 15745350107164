import { PerspectiveCamera, Scene } from 'three';

import ScrollAnimationsHandler from '../ScrollAnimationsHandler';
import Images from './animations/Images';
import Render from './animations/Render';
import Background from './animations/Background';
import BackgroundTriangle from './animations/BackgroundTriangle';
import { CoverImages } from './animations/CoverImages';

export default class CanvasScrollAnimationsHandler extends ScrollAnimationsHandler {
  constructor(opts) {
    super();

    this.delta = 0;

    this.container = opts.container;

    this.items = [];

    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;

    this.scene = new Scene();
    this.camera = new PerspectiveCamera(
      70,
      this.width / this.height,
      100,
      2000
    );
    this.camera.position.z = 600;
    this.camera.fov = 2 * Math.atan(this.height / 2 / 600) * (180 / Math.PI);

    this.params = {
      container: this.container,
      scene: this.scene,
      camera: this.camera,
    };

  }

  refreshChildren(){
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.camera.aspect = this.width / this.height;
    this.camera.fov = 2 * Math.atan(this.height / 2 / 600) * (180 / Math.PI);

    this.camera.updateProjectionMatrix();
  }

  onResize() {
    super.onResize();
    this.refreshChildren();
  }

  addNew() {
    const animations = document.querySelectorAll('[canvas-scroll-anim]');
    for (const a of animations) {
      
      const type = a.getAttribute('canvas-scroll-anim');
      a.removeAttribute('canvas-scroll-anim');

      if (type.includes('images')) this.items.push(new Images(a, this.params));
      if (type.includes('cover')) this.items.push(new CoverImages(a, this.params));
      if (type.includes('render')) this.items.push(new Render(a, this.params));
      if (type.includes('background')) this.items.push(new Background(a, this.params));
      if (type.includes('svg-bg-left')) {
        this.items.push(
          new BackgroundTriangle(a, {
            left: true,
            ...this.params,
          })
        );
      }
      if (type.includes('svg-bg-right')) {
        this.items.push(
          new BackgroundTriangle(a, {
            left: false,
            ...this.params,
          })
        );
      }
    }
  }
}
