import DefaultAnimation from '../../DefaultAnimation';


export default class TextContent extends DefaultAnimation {
  constructor(el) {
    super(el);

    this.items = this.dom.querySelectorAll('[content-item]');
    if(this.items.length === 0){
      this.items = this.dom.querySelectorAll('p, h2, h3, h1');
    }

    for(const item of this.items) item.classList.add('content-item-animation')

  }

  show() {
    if(this.visible) return;
    super.show();
    for(const item of this.items) item.classList.add('active')
  }

  // hide(){
  //   if(!this.visible) return;
  //   super.hide();
  //   for(const item of this.items) item.classList.remove('active')
  // }
}
