import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText.js';
import { ANIMATIONS_DURATION } from '../../core/Global';

gsap.registerPlugin(SplitText);

export class MenuItems {
  constructor(dom) {
    this.dom = dom;
    this.items = this.dom.querySelectorAll('a');

    this.tl = null;

    this.setup();
  }

  activeItem(slug){
    for(const item of this.items){
      item.classList.remove('navigation__active');

      if(item.dataset.page === slug) item.classList.add('navigation__active');
    }
  }

  setup() {
    const texts = [];
    for (const item of this.items) {
      const split = new SplitText(item, { type: 'chars' });
      item.style.overflow = 'hidden';
      gsap.set(split.chars, { yPercent: 100 });
      texts.push(split);
    }

    this.tl = gsap.timeline({
      paused: true,
    });

    let i = 0;
    for (const text of texts) {
      this.tl.add(
        gsap.to(text.chars, {
          yPercent: 0,
          duration: ANIMATIONS_DURATION.normal,
          ease: 'default',
          stagger: {
            each: 0.04,
            from: 'center',
          },
        }),
        i === 0 ? '>' : `>-${ANIMATIONS_DURATION.normal * 0.9}`
      );

      i++;
    }
  }

  show() {
    document.querySelector('body').classList.add('active-nav');
    document.querySelector('body').classList.add('show-nav');
    this.tl.timeScale(1);
    gsap.to(this.items, {
      autoAlpha: 1,
      duration: ANIMATIONS_DURATION.fast,
      onComplete: () => {
        this.tl.play(0);
      },
    });
  }
  hide() {
    gsap.to(this.items, {
      autoAlpha: 0,
      duration: ANIMATIONS_DURATION.fast,
      ease: 'default',
      onComplete: () => {
        document.querySelector('body').classList.remove('active-nav');
        this.tl.pause(0);
        this.tl.progress(0);
        setTimeout(() => {
          document.querySelector('body').classList.remove('show-nav');
        }, 1000);
        // Cuidado que aquest timeout sigui suficient per a que no es talli l'animació pero mes petit que el de transitionInProgress de Navigation
      },
    });
  }
}
