import { AssetsBundle, KTX2Asset } from '@jocabola/io';
import { Camera, Clock, LinearEncoding, NoToneMapping, Object3D, Scene, sRGBEncoding, Texture, TextureEncoding, ToneMapping, WebGLRenderer } from 'three';

type ToneMappingOptions = {
	mode:ToneMapping;
	exposure:number;
	outputEncoding:TextureEncoding
}

export default class glScene {
	scene: Scene = new Scene();
	camera: Camera;
	container: Object3D = new Object3D();
	width: number;
	height: number;
	scroll: number;
	page: string;
	protected _loaded: Boolean = false;
	protected _assets: AssetsBundle = new AssetsBundle();
	protected clock: Clock = new Clock(false);
	protected active: Boolean = false;
	renderer:WebGLRenderer;
	hdri:KTX2Asset;
	toneMapping:ToneMappingOptions;

	constructor(width: number, height: number, renderer:WebGLRenderer, hdri:KTX2Asset) {
		this.renderer = renderer;
		this.width = width;
		this.height = height;
		this.scene.add(this.container);
		this.hdri = hdri;

		this.page = '';

		this.toneMapping = {
			mode: NoToneMapping,
			exposure: 1,
			outputEncoding: LinearEncoding
		}
	}

	load() {
		this._assets.loadAll();
	}

	get loaded(): Boolean {
		return this._loaded;
	}

	set envMap(tex:Texture) {
		// this.scene.environment = tex;
	}

	onResize(width: number, height: number) {
		this.width = width;
		this.height = height;
	}

	protected onLoaded() {
		// console.log("LOADED");
		this._loaded = true;
		this.clock.start();
	}

	get progress (): number {
		return this._assets.getProgress();
	}

	activate () {
		this.renderer.toneMapping = this.toneMapping.mode;
		this.renderer.toneMappingExposure = this.toneMapping.exposure;
		this.renderer.outputEncoding = this.toneMapping.outputEncoding;
		// console.log("activate", this.renderer.toneMapping);		
	}

	update() {
		if (!this._loaded && this.progress === 1) {
			this._loaded = true;
			this.onLoaded();
		}
	}

	render() {
		if (!this.loaded || !this.active) return;
		this.renderer.render(this.scene, this.camera);
	}

	dispose () {
		
	}

	transitionIn(resolve){
		resolve();		
	}

	transitionOut(resolve){
		resolve();
	}
}