import { Clock } from 'three';
import DefaultAnimation from '../DefaultAnimation';
import { Random } from '@jocabola/math'
import { SCROLL_POSITION } from '../../partials/Scroller';

export default class CanvasDefaultAnimation extends DefaultAnimation {
  constructor(el, opts) {
    super(el);

    this.container = opts.container;
    this.scene = opts.scene;
    this.camera = opts.camera;
    this.canvasHeight = 0;

    this.offset = 0.2;

    this.clock = new Clock(false);
    this.time = 0;
    
    this.z = Random.random();

    this.mesh = null;
  }
  
  setPosition() {
    if(!this.mesh) return;
    this.mesh.position.y = SCROLL_POSITION.current - this.top  + this.window.h * .5 - this.rect.height * .5;
    this.mesh.position.x = this.rect.left - this.window.w * .5 + this.rect.width * .5;
  }
}
