import { isTouchDevice } from '@jocabola/utils';
import ScrollAnimationsHandler from '../ScrollAnimationsHandler';
import CoverText from './animations/CoverText';
import Footer from './animations/Footer';
import List from './animations/List';
import Sticky from './animations/Sticky';
import TextContent from './animations/TextContent';
import TextTitle from './animations/TextTitle';

export class DomScrollAnimationsHandler extends ScrollAnimationsHandler {
  return;
  addNew() {
    const animations = document.querySelectorAll('[scroll-anim]');
    for (const a of animations) {
     
      const type = a.getAttribute('scroll-anim');
      a.removeAttribute('scroll-anim');

      if (type.includes('cover-text')) this.items.push(isTouchDevice() ? new TextContent(a) : new CoverText(a));
      if (type.includes('sticky')) this.items.push(new Sticky(a));
      if (type.includes('list')) this.items.push(new List(a));
      if (type.includes('text-title')) this.items.push(isTouchDevice() ? new TextContent(a) : new TextTitle(a));
      if (type.includes('text-content')) this.items.push(new TextContent(a));
      if (type.includes('footer')) this.items.push(new Footer(a));

    }
  }

}
