import {
  LinearMipMapLinearFilter,
  Mesh, PlaneGeometry, ShaderMaterial, TextureLoader, Vector2, VideoTexture
} from 'three';

import { MathUtils } from '@jocabola/math';
import imagesLoaded from 'imagesloaded';

import CanvasDefaultAnimation from '../CanvasDefaultAnimation';

import fragmentShader from '../../../glsl/render.frag';
import vertexShader from '../../../glsl/render.vert';
import { SCROLL_POSITION } from '../../../partials/Scroller';

const textureLoader = new TextureLoader();

export default class Render extends CanvasDefaultAnimation {
  constructor(el, opts) {
    super(el, opts);

    this.domEls = this.dom.querySelectorAll('img, video');;

    this.getPosition();

    this.textures = {
      texture0: undefined,
      texture1: undefined,
    };

    imagesLoaded(this.domEls, { background: true }, () => {
      this.loadTextures().then(() => {
        this.addImages();

        this.needsUpdate = true;
      });
    });

    this.prevX = 0.5;
    this.x = 0.5;
    this.binds();
  }

  binds() {

    const mousemove = (e) => {
      let x = 0;
      if(e.type === 'touchmove'){
        x = e.touches[0].pageX - e.touches[0].target.offsetLeft;
      } else {
        x = e.offsetX;
      }

      this.x = MathUtils.map(x, 0, this.rect.width, 0, 1);
    };

    const mouseleave = (e) => {
      this.x = 0.5;
    };

    this.dom.addEventListener("touchmove", mousemove, false);
    this.dom.addEventListener("touchend", mouseleave, false);
    this.dom.addEventListener('mousemove', mousemove, true);
    this.dom.addEventListener('mouseleave', mouseleave, true);
  }

  loadTextures() {
    let promises = [];

    let index = 0;

    for (const el of this.domEls) {
      const src = el.getAttribute('src');
      promises.push(
        new Promise((resolve, reject) => {
          if (!src) {
            resolve({ texture: null, index });
            return;
          }
          if (el.tagName === 'IMG') {
            textureLoader.load(src, (texture) => {
              resolve({ texture, index });
            });
          } else {
            let videoLoaded = () => {
              if (el.readyState > 3) {
                const texture = new VideoTexture(el);
                return resolve({ texture, index });
              }
              requestAnimationFrame(videoLoaded);
            };
            videoLoaded();
          }
        })
      );
      index++;
    }

    return new Promise((resolve, reject) => {
      Promise.all(promises).then((promises) => {
        promises.forEach((promise, index) => {
          this.textures[`texture${index}`] = promise.texture;
          promise.texture.minFilter = LinearMipMapLinearFilter;
        });
        this.needsUpdate = true;
        resolve();
      });
    });
  }

  addImages() {
    this.material = new ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        speed: { value: 0 },
        resolution: {
          value: new Vector2(0, 0),
        },
        uImage0: { value: null },
        uImage1: { value: null },
        mousePosition: { value: 0.5 },
      },
      transparent: false,
      fragmentShader: fragmentShader,
      vertexShader: vertexShader,
      wireframe: false,
    });

    let texture0 = this.textures.texture0;
    let texture1 = this.textures.texture1;
    texture0.needsUpdate = true;
    texture1.needsUpdate = true;

    const geometry = new PlaneGeometry(1, 1, 15, 15);
    const material = this.material.clone();

    material.uniforms.uImage0.value = texture0;
    material.uniforms.uImage1.value = texture1;

    this.mesh = new Mesh(geometry, material);

    this.scene.add(this.mesh);

    this.mesh.material.uniforms.resolution.value.x = this.rect.width;
    this.mesh.material.uniforms.resolution.value.y = this.rect.height;
  }

  getPosition(){

    if(!this.domEls) return;

    this.window = {
      h: window.innerHeight,
      w: window.innerWidth,
    };

    this.rect = this.domEls[0].getBoundingClientRect();
    this.top = this.rect.top + SCROLL_POSITION.current;
    this.bottom = this.top + this.rect.height;

    if(!this.mesh) return;

    this.mesh.material.uniforms.resolution.value.x = this.rect.width;
    this.mesh.material.uniforms.resolution.value.y = this.rect.height;

    this.mesh.scale.set(
      this.rect.width,
      this.rect.height,
      1
    );

  }

  disable() {
    super.disable();

    this.mesh.geometry.dispose();
    this.mesh.material.dispose();
    this.scene.remove(this.mesh);
    this.textures = null;
  }

  update() {    
    if(!this.visible) return;
    if(!this.mesh) return;

    this.setPosition();
    this.time += 0.05;

    // Update materials
    this.mesh.material.uniforms.time.value = this.time;
    this.mesh.material.uniforms.speed.value = this.delta;

    const currentX = MathUtils.lerp(this.prevX, this.x, 0.1);
    this.prevX = currentX;
    this.mesh.material.uniforms.mousePosition.value = this.prevX;
  }
}
