import { Color, DoubleSide, MeshBasicMaterial, MeshPhysicalMaterial, MeshStandardMaterial, RawShaderMaterial, ShaderChunk } from 'three';
import f_prnt from "../../glsl/3dprint/print_pbr.frag";
import v_prnt from "../../glsl/3dprint/print_pbr.vert";
import f_backdrop from "../../glsl/backdrop.frag";
import v_backdrop from "../../glsl/backdrop.vert";
import coneF from "../../glsl/hologram/cone.frag";
import coneV from "../../glsl/hologram/cone.vert";
import holoF from "../../glsl/hologram/hologram.frag";
import holoV from "../../glsl/hologram/hologram.vert";
import dithering from '../../glsl/lib/dithering.glsl';
// ---------------------------------
import noise3d from '../../glsl/lib/noise3D.glsl';
import phone from "../../glsl/phonebooth/booth.frag";
import qfrag from "../../glsl/quad.frag";
import qvert from "../../glsl/quad.vert";
import ffrag from "../../glsl/phonebooth/floor.frag";
import f_tv_screen from "../../glsl/tv/screen.frag";
// import uv from "../../glsl/debug/uv.frag";
import v_tv_screen from "../../glsl/tv/screen.vert";
import comp from "../../glsl/vfx/comp.frag";
import screen from "../../glsl/vfx/screen.frag";
import f_pscr from "../../glsl/vfx/sprite.frag";
import v_pscr from "../../glsl/vfx/sprite.vert";
import wormhole from "../../glsl/vfx/wormhole.frag";

ShaderChunk.noise3D = noise3d;
ShaderChunk.basicDithering = dithering;

export const DEBUG_MAT: MeshBasicMaterial = new MeshBasicMaterial({
	color: 0x999999,
	wireframe: true,
  visible: false
});

export const PBR = new MeshStandardMaterial({
	color: 0xffffff,
	roughness: 1,
	metalness: 1,
	envMapIntensity: 1
});

export const CONE: RawShaderMaterial = new RawShaderMaterial({
	vertexShader: coneV,
	fragmentShader: coneF,
	uniforms: {
		time: {
			value: 0,
		},
	},
	transparent: true,
	// depthTest: false,
	depthWrite: false,
	side: DoubleSide,
	// blending: AdditiveBlending
});

export const TV_SCREEN = new RawShaderMaterial({
	vertexShader: v_tv_screen,
	fragmentShader: f_tv_screen,
	uniforms: {
		map: { value: null },
		video: {value: null},
		ui: {value: null},
		videoP: {value: 0},
		time: {value: 0},
		transition: {value: 0},
		off: {value: 0},
		pTrans: {value: 0}
	}
});

export const PRTSCR = new RawShaderMaterial({
	vertexShader: v_pscr,
	fragmentShader: f_pscr,
	uniforms: {
		time: {
			value: 0
		},
		map: {
			value: null
		},
		selected: {
			value: 0
		}
	},
	side: DoubleSide
});

export const PRNT = new MeshStandardMaterial({
	roughness: .8,
	metalness: 0,
	color: 0xf8f486,
	side: DoubleSide
});

export const HOLO: RawShaderMaterial = new RawShaderMaterial({
	vertexShader: holoV,
	fragmentShader: holoF,
	uniforms: {
		time: {
			value: 0,
		},
		map: {
			value: null,
		}
	},
	// transparent: true,
	// blending: AdditiveBlending
});

const BACKDROP_COLORS = data.backdrop;

// console.log('BACKDROP COLORS ==> ',BACKDROP_COLORS);
export const BACKDROP = new RawShaderMaterial({
	vertexShader: v_backdrop,
	fragmentShader: f_backdrop,
	uniforms: {
		color1: {
			value: [
				new Color(BACKDROP_COLORS[0].light),
				new Color(BACKDROP_COLORS[0].dark)
			]
		},
		color2: {
			value: [
				new Color(BACKDROP_COLORS[1].light),
				new Color(BACKDROP_COLORS[1].dark)
			]
		},
		color3: {
			value: [
				new Color(BACKDROP_COLORS[2].light),
				new Color(BACKDROP_COLORS[2].dark)
			]
		},
		color4: {
			value: [
				new Color(BACKDROP_COLORS[3].light),
				new Color(BACKDROP_COLORS[3].dark)
			]
		},
		colorRamp: {
			value: 0
		}
	}
});

export const FLOOR_MAT = new RawShaderMaterial({
	vertexShader: qvert,
	fragmentShader: ffrag,
	uniforms: {
		tInput: {value: null}
	},
	transparent: true
});

export const PHONEBOX_MAT = new MeshPhysicalMaterial({
	roughness: 1,
	metalness: 1,
	emissiveIntensity: 1.25,
	emissive: 0xffffff,
	envMapIntensity: 2,
	reflectivity: 0.7
	
});

export const GLASS_MAT = new MeshPhysicalMaterial({
	roughness: 0.90,
	metalness: 0,
	envMapIntensity: 1,
	transmission: 1.0,
	reflectivity: .85,
	transparent: true,
	depthTest: false
});

export const SCREEN_FBO = new RawShaderMaterial({
	vertexShader: qvert,
	fragmentShader: screen,
	uniforms: {
		tVideo: {
			value: null
		},
		tLogo: {
			value:null
		},
		time: {
			value: 0
		},
		mode: {
			value: 0
		}
	},
	transparent: true
});

export const HOLE_MAT = new RawShaderMaterial({
	vertexShader: qvert,
	fragmentShader: wormhole,
	uniforms: {
		map: {
			value: null
		},
		mode: {
			value: 0
		},
		time: {
			value: 0
		}
	}
});

export const COMP_PASS = new RawShaderMaterial({
  vertexShader: qvert,
  fragmentShader: comp,
  uniforms: {
    tHolo: {
      value: null
    },
    tBlur: {
      value: null
    },
    t: {
      value: 0
    }
  },
  transparent: true
})

export const PHONE = phone;
export const V_PRNT = v_prnt;
export const F_PRNT = f_prnt;