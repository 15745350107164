
import { DomScrollAnimationsHandler } from '../animations/domScrollAnimations/DomScrollAnimationsHandler';

import * as Ajax from '../sys/Ajax';

import { gsap } from 'gsap'
import { GlLayer } from '../glLayer/GlLayer';
import { initStats, stats } from './dev';
import { CURRENT_PAGE, CURSOR, CURSOR_POSITION, DEBUG, FALLBACK, HEADER, IS_DEV_MODE, PAGES, PALETTES, SCROLLER, SITE_LOADER } from './Global';
import { Page, PAGE_TRANSITION_DURATION } from './Page';

export const TRANSITION = {
	inProgress: false
}


export class App {
	content:HTMLElement;
	animations:DomScrollAnimationsHandler;
	glLayer:GlLayer;
	currentPage:Page;

	constructor(){

		console.log('%cSite developed by Fil Studio', "color:white;font-family:system-ui;font-size:1rem;font-weight:bold");


		/* FALLBACK HANDLER */
		if(FALLBACK){
			// console.log('Fallback MODE')
		}

		/* DEV MODE HANDLER */
		if(IS_DEV_MODE){
			this.onDevMode();
		}

		/* DEBUG HANDLER */
		if(DEBUG){
			this.onDebugMode();
		}

		this.content = document.querySelector('#site__wrapper');
		CURRENT_PAGE.slug = this.content.dataset.slug;
		document.body.classList.add(`template__${CURRENT_PAGE.slug}`);

		this.createApp();

		// --------------------------------------------------------------- Init Raf -- //
		initStats();

		let loaderComplete = false;

		const animate = () => {
			requestAnimationFrame(animate);

			if(!loaderComplete && SITE_LOADER.complete) {
				loaderComplete = true;
				SCROLLER.resume()
			}

			if(!SITE_LOADER.complete){
				SITE_LOADER.update(this.glLayer.progress)
				return;
			}

			if(IS_DEV_MODE) stats.begin();
			if(SITE_LOADER.complete) this.update();
			if(IS_DEV_MODE) stats.end();
		};
		animate();
	}

	onDevMode(){
		// console.log('== DEV MODE ==');
	}
	onDebugMode(){
		// console.log('== DEBUG ACTIVE ==');
	}

	private registerEvents(){

		// MOUSE
		document.addEventListener('mousemove', this.onMouseMove.bind(this));

		// RESIZE
		window.addEventListener('resize', this.onResize.bind(this));

		// Ajax
		window.addEventListener('popstate', (e) => {
			this.onChange({
				url: window.location.pathname
			})
		});

		window.onblur = () => {
			// console.log('onBlur');
			window.dispatchEvent(new Event('resize'));

			// const videos = document.querySelectorAll('#site__wrapper video');
			// for(const video of videos){
			// 	video.style.opacity = '1';
			// }
		}

		window.onfocus = () => {
			// console.log('onFocus');
			window.dispatchEvent(new Event('resize'));
			// const videos = document.querySelectorAll('#site__wrapper video');
			// for(const video of videos){
			// 	video.style.opacity = '0';
			// }
		}

		// Ajax links (first load)
		this.addLinksEventListeners();
	}

	onMouseMove(e) {

		CURSOR_POSITION.lastX = CURSOR_POSITION.x;
		CURSOR_POSITION.lastY = CURSOR_POSITION.y;

		CURSOR_POSITION.x = e.clientX;
		CURSOR_POSITION.y = e.clientY;

		CURSOR.onMouseMove(e);
	}

	onResize(){
		// if(isIphone() || isIpad()) SCROLLER.disable();
		// else SCROLLER.enable();
		SCROLLER.enable();

		CURSOR.onResize()
		HEADER.onResize();

		if (!!this.animations) this.animations.onResize();
		if (!!this.glLayer) this.glLayer.onResize();

	}

	/**
	 * App
	 */
	createApp(){
		this.createComponents();
		this.createPages();
		this.registerEvents();
	}

	/**
	 * Components
	 */
	createComponents(){

		// ---------------------------------------------------------------- Animations -- //
		this.animations = new DomScrollAnimationsHandler();

		// ---------------------------------------------------------------- GLLAYER -- //
		this.glLayer = new GlLayer(document.querySelector('.canvas__wrapper'));
	}

	/**
	 * Pages
	 */

	// Todo refer aixo amb el history de l'edu que es molt més senzill
	async createPages(){

		PAGES.set('home', new Page())
		PAGES.set('contact', new Page())
		PAGES.set('education', new Page())
		PAGES.set('project', new Page())

		this.currentPage = PAGES.get(CURRENT_PAGE.slug) || PAGES.get('home')
		this.currentPage.create()

		await this.pageLoaded()
		this.onResize();
		this.currentPage.show()
	}

	addLinksEventListeners(){

		const externalLinks = document.querySelectorAll(`a[href*="http"]`);
		for(const link of externalLinks){
			link.setAttribute('target', '_blank');
			link.setAttribute('data-cursor', 'hover');
		}

		const links = document.querySelectorAll('a:not(.ajax__enabled):not([target="_blank"]):not([href^="mailto:"]):not([href^="tel:"])');

		for(const a of links){
			a.classList.add('ajax__enabled');
			a.setAttribute('data-cursor', 'hover');

			const href = a.getAttribute('href');

			// If its an internal link
			if (href.indexOf(window.location.hostname) === -1) {
				const scrollTo = a.hasAttribute('scroll-to') ? a.getAttribute('scroll-to') : 0;

				a.addEventListener('click', e => {
					e.preventDefault();

					const el = e.target as HTMLElement;

					if(el.getAttribute('href') === window.location.pathname){
						this.anchorSamePage(scrollTo);
						return;
					}
					this.onChange({
						url: href,
						scrollTo
					})
				});

			} else if (href.indexOf('mailto') > -1) {
				a.setAttribute('target', '_blank');
			}

		}
	}

	onChange({push = true, url = null, scrollTo = null}) {
		// console.log('ONCHANGE');

		TRANSITION.inProgress = true;

		// Fix to give UX feedback on the ongoing transition
		if(CURRENT_PAGE.slug === 'home') {
			gsap.to(document.getElementById('site__wrapper'), {autoAlpha: 0, duration: .5, ease: "power2.inOut"});
		}

		Ajax.get(url).then(response => {
			this.onRequest({
				push,
				response,
				url,
				scrollTo
			});
		}).catch(response => {
			this.onRequest({
				push,
				response,
				url: '/',
				scrollTo
			});
		})
	}

	async onRequest({push, response, url, scrollTo}) {
		// console.log('==> Request new page <==');

		const previousSlug = CURRENT_PAGE.slug;
		SCROLLER.pause();

		// ----------------------------------- Get Contents
		const html = document.createElement('div')
		html.innerHTML = response
		const newContent = html.querySelector('.ajax__wrapper') as HTMLElement
		CURRENT_PAGE.slug = newContent.dataset.slug;


		// ----------------------------------- Transition out
		// console.log('==> Page transition Hide <==');

		await this.glLayer.transitionOut();
		setTimeout(() => {
			document.body.classList.remove(`template__${previousSlug}`)
		}, PAGE_TRANSITION_DURATION * 1000);
		await this.currentPage.hide()
		if(this.animations) this.animations.disable();
		if(this.glLayer) this.glLayer.disable();


		// ----------------------------------- SWAP HTML
		this.content.replaceWith(newContent)
		this.content = document.querySelector('#site__wrapper');
		document.title = html.querySelector('title').textContent

		// ----------------------------------- Update content & slug

		const page = PAGES.get(CURRENT_PAGE.slug);
		// console.log(`==> Page loaded: ${CURRENT_PAGE.slug} <==`);
		page.create();

		// ----------------------------------- Push State
		if (push) window.history.pushState({}, document.title, url)

		await this.pageLoaded();
		SCROLLER.scrollTo(scrollTo)

		// ----------------------------------- Transition in
		// console.log('==> Page transition Show <==');
		HEADER.close();
		setTimeout(() => {
			document.body.classList.add(`template__${CURRENT_PAGE.slug}`)
		}, PAGE_TRANSITION_DURATION * 1000);

		await page.show()
		await this.glLayer.transitionIn();

		SCROLLER.resume()
		TRANSITION.inProgress = false;


		this.currentPage = page;
	}

	async pageLoaded(){
		PALETTES.update();

		CURSOR.onReset()

		this.addLinksEventListeners();

		this.glLayer.page = CURRENT_PAGE.slug;
		this.glLayer.CanvasScrollAnimations?.addNew();
		this.animations.addNew()

		return new Promise(resolve => {
			resolve(true);
		})
	}

	anchorSamePage(scrollTo){
		HEADER.close();
		SCROLLER.scrollTo(scrollTo);
	}

	/**
	* RAF
	*/
	update(){
		SCROLLER.update();
		CURSOR.update();

		if (!!this.glLayer) this.glLayer.update();
		if (!!this.animations) this.animations.update();
	}
}
