import { MathUtils } from '@jocabola/math';
import { SCROLL_POSITION } from '../../../partials/Scroller';
import DefaultAnimation from '../../DefaultAnimation';

export default class List extends DefaultAnimation {
  constructor(el) {
    super(el);

    this.currentVideoItem = null;

    this.hasActiveState = this.dom.hasAttribute('show-list-info');

    this.list = this.dom.querySelector('[list-el]');

    const listItems = this.list.querySelectorAll('[list-item]');
    this.items = [];

    for(const _item of listItems){

      const rect = _item.getBoundingClientRect();

      const item = {
        index: parseInt(_item.getAttribute('list-item')),
        dom: _item,
        span: _item.querySelector('span'),
        rect: rect,
        top: rect.top + SCROLL_POSITION.current,
        scale: 1,
        active: false,
        hover: false
      }

      this.items.push(item);
    }

    if (this.hasActiveState) {
      this.addEventListeners();
    }

    this.getPosition();
  }

  getPosition(){
    super.getPosition();

    if(!this.items) return;

    for(const item of this.items){
      item.rect = item.dom.getBoundingClientRect();
      item.top = item.rect.top + SCROLL_POSITION.target;
      item.bottom = item.top + item.rect.height;
    }
  }

  addEventListeners() {

    for (const item of this.items) {
      item.dom.addEventListener('mouseenter', () => {
        item.hover = true;
      });

      item.dom.addEventListener('mouseleave', () => {
        item.hover = false;
      });
    }

  }

  emitEvent(){

    // NO current item
    if (!this.currentVideoItem) {

      const projectChange = new CustomEvent('projectChange', {
          detail: {
            id: null,
            video: null,
          },
        });

      window.dispatchEvent(projectChange)

      return;
    }

    const item = this.currentVideoItem;

    const projectChange = new CustomEvent('projectChange', {
      detail: {
        id: item.index,
        video: item.dom.getAttribute('video-preview'),
        year: item.dom.getAttribute('year'),
        title: item.dom.getAttribute('link-title'),
        textColor: item.dom.getAttribute('text-color') == 'true' ? true : false
      },
    });

    window.dispatchEvent(projectChange)

  }

  hide() {
    if(!this.visible) return;
    super.hide();
    this.currentVideoItem = null;
    for (const item of this.items) {
      item.active = false;
      item.dom.classList.remove('active');
    }
  }

  updateScaleItems(){

    const halfH = this.window.h * .5;
    const windowCenter = SCROLL_POSITION.current + halfH;

    for (const item of this.items) {

      const halfItemH = item.rect.height * .5;

      const itemCenter = item.top + halfItemH;

      item.active = itemCenter < windowCenter + halfItemH && itemCenter > windowCenter - halfItemH;

      const absCenter = Math.abs(itemCenter - windowCenter);

      item.scale = MathUtils.clamp(MathUtils.map(absCenter, 0, halfH, 1, 0.5), 0, 1)

      item.span.style.transform = `scale3d(${item.scale}, ${item.scale}, 1)`;
    }

  }

  updateActiveItems(){

    // Check if there is an hover active item
    const hoverActive = this.items.filter(x => x.hover).length > 0;

    for(const item of this.items){
      item.active = item.hover ? true : hoverActive ? false : item.active;
      if(item.active) {
        item.dom.classList.add('active');
      } else item.dom.classList.remove('active');
    }
  }

  updateCurrentItem() {
    if (!this.hasActiveState) return;

    const activeItem = this.items.filter(x => x.active);

    // No active item
    if(activeItem.length === 0) {
      this.currentVideoItem = null;
      this.emitEvent();
      return;
    }

    // Same active item
    if(activeItem[0] === this.currentVideoItem) return;

    // Different active item
    this.currentVideoItem = activeItem[0];
    this.emitEvent();
  }

  update() {
    if(!this.visible) return;
    if (!!!this.list) return;

    this.updateScaleItems();

    this.updateActiveItems();

    this.updateCurrentItem();
  }
}
