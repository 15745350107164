import { SCROLL_POSITION } from "../partials/Scroller";

const DURATION = 0.1;
const EASE = 'linear';

export default class DefaultAnimation {

  constructor(el) {
    this.dom = el;

    this.offset = 0;

    this.delta = 0;

    this.trigger = 0;

    this.needsUpdate = false;

    this.visible = false;

    this.ease = EASE;
    this.duration = DURATION;

    this.getPosition();
  }
  
  getPosition() {

    this.window = {
      h: window.innerHeight,
      w: window.innerWidth,
    };
    this.rect = this.dom.getBoundingClientRect();
    this.top = this.rect.top + SCROLL_POSITION.target;
    this.bottom = this.top + this.rect.height;

  }

  onResize() {
    // this.visible = false 
    this.getPosition();
    this.setPosition();
  }

  setPosition(){

  }

  show() {
    if(this.visible) return;
    // this.dom.classList.add('visible');
    this.visible = true;
  }

  hide() {
    if(!this.visible) return;
    // this.dom.classList.remove('visible');
    this.visible = false;
  }

  disable() {}

  checkVisible(){

    const offset = this.offset * this.window.h;
    const scroll = SCROLL_POSITION.current;

    if(scroll + this.window.h + offset > this.top && scroll - offset < this.bottom){ 
      this.show();
    } else this.hide()

  }
  
  update() {
  }
}
