import { Object3D, PerspectiveCamera, Raycaster, Vector2 } from "three";
import { CURSOR } from "../../../core/Global";

const raycaster = new Raycaster();
const iObjects = [];
const OFFSET = .006;

let currentIO:Object3D = null;

export const holoI = {
	node: null,
	cone: null,
	left: true
}

const resetIO = (object:Object3D) => {
	if(object['iZ']) {
		object.position.z = object['oZ'];
		// object.scale.y = 1;
	} else {
		object.position.y = object['oY'];
	}
}

export const addIObject = (node:Object3D) => {
	if(node.name.indexOf('button_right') > -1 || node.name.indexOf('button_left') > -1) {
		node['iZ'] = true;
		node['oZ'] = node.position.z;
	} else {
		node['iZ'] = false;
		node['oY'] = node.position.y;
	}

	iObjects.push(node);
}

export const hover3D = (mouse:Vector2, camera:PerspectiveCamera):Object3D => {
	raycaster.setFromCamera(mouse, camera);
	const i = raycaster.intersectObjects(iObjects, true);	

	if(i.length) {
		if(currentIO != null && currentIO != i[0].object) {
			resetIO(currentIO);
		}
		currentIO = i[0].object;
		CURSOR.stats.to = 'hover';
	} else {
		holoI.node = null;
		if(currentIO != null) {
			resetIO(currentIO);
			CURSOR.stats.to = 'default';
		}
		currentIO = null;
	}

	return currentIO;
}

export const down3D = ():Object3D => {
	
	if(currentIO != null) {
		if(currentIO['iZ']) {
			currentIO.position.z = currentIO['oZ'] - OFFSET;
			// currentIO.scale.y = .2;
			holoI.left = currentIO.name.indexOf('left') > -1;
			for(const node of currentIO.parent.children) {
				if(node.name.toLowerCase().indexOf('hologram') > -1 &&
				node.name.toLowerCase().indexOf('shadow') == -1) {
					holoI.node = node;
				} else if(node.name.toLowerCase().indexOf('cone') > -1) {
					holoI.cone = node;
				}
			}
		} else {
			holoI.node = null;
			currentIO.position.y = currentIO['oY'] - OFFSET;
		}
	}

	return currentIO;
}

export const up3D = () => {
	holoI.node = null;
	if(currentIO != null) {
		resetIO(currentIO);
	}
}