import { MathUtils, Random } from "@jocabola/math";
import { isTouchDevice } from "@jocabola/utils";
import gsap from "gsap/all";
import { LinearFilter, LinearMipMapLinearFilter, Mesh, PlaneGeometry, RGBAFormat, ShaderMaterial, TextureLoader, Vector2 } from "three";
import { CURSOR_POSITION } from "../../../core/Global";
import fragmentShader from '../../../glsl/image.frag';
import vertexShader from '../../../glsl/image.vert';
import { SCROLL_POSITION } from "../../../partials/Scroller";
import CanvasDefaultAnimation from "../CanvasDefaultAnimation";

const GREYSCALE_OFFSET = .3;
const MIN_PIXEL_RADIUS = 0.0;
const MAX_PIXEL_RADIUS = 0.25;
const RADIUS = {
  target: MIN_PIXEL_RADIUS,
  easeIn: 0.16,
  easeOut: 0.06
}

const textureLoader = new TextureLoader();

export class CoverImages extends CanvasDefaultAnimation {
  constructor(el, opts) {
    super(el, opts);

    this.material = new ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        speed: { value: 0 },
        resolution: {
          value: new Vector2(0, 0),
        },
        uImage: { value: null },
        greyScale: { value: 0 },
        cover: { value: !isTouchDevice() },
        mouse: { value: new Vector2(0, 0) },
        radius: { value: 0 }
      },
      transparent: false,
      fragmentShader: fragmentShader,
      vertexShader: vertexShader,
      wireframe: false,
    });

    this.mesh = null;
    this.parallax = isTouchDevice() ? 1 : 0.85;
    this.texture = null;
    this.greyscale = false;

    this.loadItem()
  }

  loadItem() {
    textureLoader.load(this.dom.src, (texture) => {
      this.texture = texture;
      this.getPosition();
      this.addMesh();
    });

  }

  addMesh() {

    const geometry = new PlaneGeometry(1, 1, 15, 15);

    this.material.transparent = this.texture.format === RGBAFormat;
    this.texture.minFilter = LinearMipMapLinearFilter;
    this.texture.magFilter = LinearFilter;

    this.material.uniforms.uImage.value = this.texture;

    this.mesh = new Mesh(geometry, this.material);

    this.mesh.material.uniforms.resolution.value.x = this.rect.width;
    this.mesh.material.uniforms.resolution.value.y = this.rect.height;

    this.scene.add(this.mesh);

    this.needsUpdate = true;
  }

  // ------------------- SHOW - HIDE
  show() {
    if(this.visible) return;
    super.show();
    this.clock.stop();
    this.clock.startTime = this.clock.elapsedTime;
    this.clock.start();
    this.time = 0;
  }

  hide() {
    if(!this.visible) return;
    super.hide();
    this.clock.stop();
  }

  disable() {
    super.disable();

    if(!this.mesh) return

    this.mesh.geometry.dispose();
    this.texture.dispose();
    this.mesh.material.dispose();
    this.scene.remove(this.mesh);

  }

  // ----------------- UPDATE
  setPosition() {
    super.setPosition();
    if(!this.mesh) return;
    // this.mesh.position.y = this.rect.top + SCROLL_POSITION.current - this.window.h * .5 + this.rect.height * .5;
    // console.log(this.mesh.position.y);
    this.mesh.position.y *= this.parallax;
    this.mesh.scale.set(this.rect.width, this.rect.height, 1);
  }

  updateMaterial() {

    if(isTouchDevice()) return;

    const mat = this.mesh.material;
    mat.uniforms.time.value = this.time;
    mat.uniforms.speed.value = isTouchDevice() && window.innerWidth < 1025 ? 0 : this.delta;

    const mouseX = MathUtils.map(CURSOR_POSITION.x - this.rect.left, 0, this.rect.width, 0, 1);
    const mouseY = MathUtils.map(CURSOR_POSITION.y + this.mesh.position.y - this.rect.top, 0, this.rect.height, 1, 0);

    mat.uniforms.mouse.value.x = MathUtils.lerp(mouseX, mat.uniforms.mouse.value.x, 0.95);
    mat.uniforms.mouse.value.y = MathUtils.lerp(mouseY, mat.uniforms.mouse.value.y, 0.95);

    const toFixed = 3;
    if(mat.uniforms.mouse.value.x.toFixed(toFixed) === mouseX.toFixed(toFixed) && mat.uniforms.mouse.value.y.toFixed(toFixed) === mouseY.toFixed(toFixed)) {
      RADIUS.target = MIN_PIXEL_RADIUS;
    } else {
      RADIUS.target = MAX_PIXEL_RADIUS;
    }
    const currentRadius = mat.uniforms.radius.value;
    mat.uniforms.radius.value = MathUtils.lerp(currentRadius, RADIUS.target, currentRadius < RADIUS.target ? RADIUS.easeIn : RADIUS.easeOut);

  }

  updateGrayscale(){

    const mat = this.mesh.material;
    const scroll = SCROLL_POSITION.current;
    const offset = this.window.h * GREYSCALE_OFFSET;

    if(this.top - this.window.h < scroll - offset && this.bottom > scroll + offset){
      if(this.greyscale){
        this.greyscale = false;
        gsap.to(mat.uniforms.greyScale, {
          value: 0,
          ease: 'default',
          duration: 0.3,
        })
      }

    } else {
      if(!this.greyscale){
        this.greyscale = true;
        gsap.to(mat.uniforms.greyScale, {
          value: 1,
          ease: 'default',
          duration: 0.3,
        })
      }
    }
  }

  update() {
    if(!this.visible) return;

    this.time = this.clock.getElapsedTime();

    if(!this.mesh) return;
    this.setPosition();
    this.updateMaterial();
    this.updateGrayscale();

  }

}