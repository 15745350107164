import { MathUtils } from '@jocabola/math';
import { isTouchDevice } from '@jocabola/utils';

import gsap from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin.js';
import { ANIMATIONS_DURATION, CURSOR_EASE, CURSOR_POSITION } from '../core/Global';

gsap.registerPlugin(MorphSVGPlugin);

export class Cursor {
  constructor() {
		this.disabled = true;
		this.disabledTimer = null;

		this.loaded = false;
		this.dom = document.querySelector('#cursor');
		this.path = this.dom.querySelector('path');

		this.domStats = document.querySelector('.cursor__stats')

		this.activeGroup = this.domStats.querySelector(`#cursor-default .cursor-path`)

		const rect = this.dom.getBoundingClientRect()

		this.size = {
			w: rect.width * .5,
			h: rect.height * .5
		}

		this.dom.style.opacity = 1

		this.position = {
			x: 0,
			y: 0
		}

		this.stats = {
			current: '',
			to: 'default'
		}
		this.changeStat();

		this.onReset()

		this.loaded = true;
  }

	onReset(){
		this.stats.to = 'default'
		this.activeGroup = this.domStats.querySelector(`#cursor-default .cursor-path`)
	}

	onResize(){
		this.stats.current = '';
		this.stats.to = 'default'
		if(this.disabled) {
			this.stats.to = 'hidden';
		}
		this.checkIsVisible();
	}

	onMouseMove(e){
		if(this.disabled){
			this.disabled = false;
			this.stats.to = 'default'
			this.changeStat();
		}

		if(!!e.target.dataset.cursor) this.stats.to = e.target.dataset.cursor

		if(this.disabledTimer) clearTimeout(this.disabledTimer)
		this.disabledTimer = setTimeout(() => {
			console.log('Fil - Disable cursor');
			this.disabled = true;
			this.changeStat();
		}, 3000);
	}

	checkIsVisible(){
		if(this.stats.to === 'hidden'){
			gsap.to(this.dom, {
				autoAlpha: 0,
				duration: ANIMATIONS_DURATION.fast,
				ease: 'default',
			})
		} else if(!isTouchDevice()){
			gsap.to(this.dom, {
				autoAlpha: 1,
				duration: ANIMATIONS_DURATION.fast,
				ease: 'default',
			})
		}
	}

	changeStat(){
		if(this.disabled) {
			this.stats.current = 'hidden';
			this.stats.to = 'hidden'
			this.checkIsVisible();
			return;
		}
		this.checkIsVisible();
		if(this.stats.to === 'hidden') return
		if(this.stats.to === this.stats.current) return;

		this.stats.current = this.stats.to;

		gsap.killTweensOf(this.path);

		this.activeGroup = this.domStats.querySelector(`#cursor-${this.stats.to} .cursor-path`)

		gsap.to(this.path, {
			duration: 0.3,
			morphSVG: this.activeGroup,
			rotation: this.stats.to === 'default' ? -35 : 0,
			ease: 'power2.inOut',
		})

	}

  update() {
		if(!this.loaded) return

		this.position.x = MathUtils.lerp(CURSOR_POSITION.x, this.position.x, CURSOR_EASE)
		this.position.y = MathUtils.lerp(CURSOR_POSITION.y, this.position.y, CURSOR_EASE)

		this.dom.style.transform = `translate3d(${this.position.x - this.size.w}px, ${this.position.y}px, 0)`;
		if(this.stats.current !== this.stats.to) this.changeStat()
  }
}
