import { DoFPass, FboUtils, FXAAPass, RenderComposer, SceneUtils } from "@jocabola/gfx";
import { GLTFAsset, KTX2Asset, TextureOptions } from "@jocabola/io";
import { MathUtils } from "@jocabola/math";
import { copyToClipboard } from "@jocabola/utils";
import gsap from "gsap";
import { AmbientLight, EquirectangularReflectionMapping, MeshPhysicalMaterial, PerspectiveCamera, RepeatWrapping, RGBAFormat, Shader, Vector2, WebGLRenderer, WebGLRenderTarget } from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TRANSITION } from "../../core/App";
import { ASSETS_URL, CURRENT_PAGE, GLB_EXT } from "../../core/Global";
import CustomVideoTexture from "../gfx/CustomVideoTexture";
import { DEBUG_MAT, FLOOR_MAT, GLASS_MAT, HOLE_MAT, PHONEBOX_MAT, SCREEN_FBO } from '../gfx/ShaderLib';
import glScene from "./glScene";
import CameraController from "./home/CameraController";

const SCREEN_MAT:MeshPhysicalMaterial = GLASS_MAT.clone();
SCREEN_MAT.depthTest = true;

const T_DURATION = 5;

type State = {
	mode:number,
	interval:number
}

export default class glContact extends glScene {
	camera: PerspectiveCamera;
	ambient:AmbientLight;
	controls:OrbitControls;
	screen:WebGLRenderTarget;
	sVideo:CustomVideoTexture;
	composer: RenderComposer;
	fxaa:FXAAPass;
	dof:DoFPass;
	cc:CameraController;
	private state:State;
	private mouse:Vector2;
	private mouseTarget:Vector2;
	private shader:Shader;
	private videoStreamed:boolean = false;

	constructor(width:number, height:number, renderer:WebGLRenderer, hdri:KTX2Asset) {
		super(width,height,renderer,hdri);

		this.cc = new CameraController(width, height);
		this.scene.add(this.cc.camera);
		this.camera = this.cc.camera;

		this.composer = new RenderComposer(width, height, {
			depth: true
		});
		this.dof = new DoFPass(width, height, {
			camNear: .1,
			camFar: 50,
			blur: {
				scale: .25,
				quality: 1,
				radius: 1,
				iterations: 4
			},
			focalDistance: 20,
			aperture: 10
		});
		this.fxaa = new FXAAPass(width, height);

		this.state = {
			mode: 0,
			interval: -1
		};
		this.mouse = new Vector2();
		this.mouseTarget = new Vector2();

		const opts:TextureOptions = {
			flipY: false
		};

		this._assets.add(
			new GLTFAsset(`${ASSETS_URL}models/phonebooth.${GLB_EXT}`)
		);

		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/floor.ktx2`, opts)
			// new TextureAsset(`${ASSETS_URL}basis/phonebooth/floor.webp`, opts)
		);

		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/diffuse_lights_on.ktx2`, opts)
		);

		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/normal.ktx2`, opts)
			// new TextureAsset(`${ASSETS_URL}basis/phonebooth/normal.jpg`, opts)
		);

		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/aorm.ktx2`, opts)
		);

		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/emissive.ktx2`, opts)
		);

		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/alpha.ktx2`, opts)
		);

		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/env.ktx2`)
		);

		// this._assets.add(this.hdri);

		// screen assets
		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/screen/screen_logo_bascolor.ktx2`, {
				flipY: false,
				format: RGBAFormat
			})
		);
		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/screen/screen_RoughnessMetallic.ktx2`, opts)
		);

		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/screen/grid.ktx2`, {
				wrapT: RepeatWrapping
			})
		);

		this._assets.add(
			new KTX2Asset(`${ASSETS_URL}basis/phonebooth/diffuse_lights_off.ktx2`, opts)
		);

		this.ambient = new AmbientLight(0x222222, 3.64);
		this.scene.add(this.ambient);

		// screen
		this.screen = FboUtils.getRenderTarget(512, 512, {
			format: RGBAFormat
		});

		this.load();

		document.addEventListener("mousemove", (event)=>{
			if(!this.active) return;
			this.mouse.x = -1 + 2 * event.clientX/window.innerWidth;
			this.mouse.y = 1 - 2 * event.clientY/window.innerHeight;
		});
	}

	onLoaded() {
		const glb = this._assets.get(0).content.scene;
		this.scene.add(glb);
		// console.log(glb);
		PHONEBOX_MAT.map = this._assets.get(2).content;
		// PHONEBOX_MAT.map = this._assets.get(11).content
		PHONEBOX_MAT.normalMap = this._assets.get(3).content;
		PHONEBOX_MAT.roughnessMap = this._assets.get(4).content;
		PHONEBOX_MAT.metalnessMap = this._assets.get(4).content;
		PHONEBOX_MAT.emissiveMap = this._assets.get(5).content;

		GLASS_MAT.map = this._assets.get(2).content;
		GLASS_MAT.normalMap = this._assets.get(3).content;
		GLASS_MAT.roughnessMap = this._assets.get(4).content;
		GLASS_MAT.metalnessMap = this._assets.get(4).content;
		GLASS_MAT.emissiveMap = this._assets.get(5).content;
		GLASS_MAT.alphaMap = this._assets.get(6).content;

		SCREEN_FBO.uniforms.tLogo.value = this._assets.get(8).content;

		PHONEBOX_MAT.onBeforeCompile = (shader) => {

			let fs = shader.fragmentShader;
			fs = fs.replace(
				`#include <common>`,
				`
				uniform sampler2D offMap;
				uniform float mode;
				uniform float time;

				#include <common>`
			);

			fs = fs.replace(
				`#include <map_fragment>`,
				`vec4 sampledDiffuseColor = texture2D( map, vUv );
				if(mode > 0.0 && mode < 1.0) {
					float t = step(.5, fract(sin(time*8.0)));
					sampledDiffuseColor = mix(sampledDiffuseColor, texture2D(offMap, vUv), t);
				}
				else if(mode == 1.0) {
					sampledDiffuseColor = texture2D(offMap, vUv);
				}
				diffuseColor *= sampledDiffuseColor;`
			);

			// fs = fs.replace(
			// 	`vec3 totalEmissiveRadiance = emissive;`,
			// 	`
			// 	vec3 totalEmissiveRadiance = emissive;

			// 	#ifdef USE_TRANSMISSION
			// 		float totalTransmission = transmission;
			// 		float thicknessFactor = thickness;
			// 	#endif`
			// );
			// fs = fs.replace(
			// 	`#include <emissivemap_fragment>`,
			// 	`
			// 	#include <emissivemap_fragment>

			// 	vec3 rawDiffuseColor = diffuseColor.rgb;`
			// );

			shader.fragmentShader = fs;
			shader.uniforms.offMap = {
				value: this._assets.get(11).content
			}
			shader.uniforms.mode = {
				value: 0
			}
			shader.uniforms.time = {
				value: 0
			}

			this.shader = shader;
		}

		/* const env = this._assets.get(7).content;
		env.mapping = EquirectangularReflectionMapping; */

		const env = SceneUtils.setHDRI(this._assets.get(7).content,	this.renderer);

		PHONEBOX_MAT.envMap = env;

		const envMap = this.hdri.content;//this._assets.get(8).content;
		envMap.mapping = EquirectangularReflectionMapping;
		GLASS_MAT.envMap = env;

		SCREEN_MAT.envMap = envMap;
		SCREEN_MAT.map = this.screen.texture;
		SCREEN_MAT.emissiveMap = this.screen.texture;
		SCREEN_MAT.emissive.setHex(0xffffff);
		SCREEN_MAT.roughnessMap = this._assets.get(9).content;
		SCREEN_MAT.metalnessMap = this._assets.get(9).content;

		HOLE_MAT.uniforms.map.value = this._assets.get(10).content;

		for(const child of glb.children) {
			// console.log(child.name);
			if(child.type == "PerspectiveCamera") {
				// console.log("Found Contact Camera:", child.name);
				child.children[0].material = DEBUG_MAT;
				if(child.name.toLowerCase().indexOf('mobile') === -1) {
					this.cc.addTarget(child);
				} else {
					this.cc.addMobileTarget(child);
				}
 				continue;
			}
			if (child.name.toLowerCase() == "ground_plane") {
				child.material = FLOOR_MAT;
				FLOOR_MAT.uniforms.tInput.value = this._assets.get(1).content;
				// this._assets.get(1).content.generateMipmaps = true;
			}
			else if (child.name.toLowerCase() == "screen") {
				child.material = SCREEN_MAT;
			}
			else if (child.name.toLowerCase() == "wormhole") {
				child.material = HOLE_MAT;
			}
			else if (child.name.toLowerCase() == "glass") {
				child.material = GLASS_MAT;
			}
			else {
				// PBR
				child.material = PHONEBOX_MAT;
			}
		}

		this.clock.start();
	}

	onResize(width: number, height: number) {
		super.onResize(width, height);
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
		this.dof.setSize(width, height);
	}

	private setScreenMode(val:number=0) {
		gsap.killTweensOf(this.shader.uniforms.mode);
		if(this.shader && val ==0) gsap.to(this.shader.uniforms.mode, {duration: .5, value: val});
		window.clearTimeout(this.state.interval);
		this.state.interval = window.setTimeout(()=>{
			gsap.to(SCREEN_FBO.uniforms.mode, {duration: 4, value: val, delay: 2});
			if(this.shader && val ==1) gsap.to(this.shader.uniforms.mode, {duration: 2, value: val});
		}, 1000);
	}

	private setupButton(){

		const el = document.querySelector('button[data-mail]');
		const mail = el.getAttribute('data-mail');

		el.addEventListener('click', () => {
			copyToClipboard(mail);
			if(!el.classList.contains('copied')){
				el.classList.add('copied');
				setTimeout(() => {
					el.classList.remove('copied');
				}, 1500);
			}

			gsap.killTweensOf(this.state);
			gsap.to(this.state, {duration: T_DURATION, mode: 1, ease: 'power4.out'});
			this.setScreenMode(1);
		})


		// Triger zoom
		document.querySelector('.contact-zoom-trigger').addEventListener('click', () => {
			gsap.killTweensOf(this.state);

			if(this.state.mode > 0.5){
				gsap.to(this.state, {duration: T_DURATION, mode: 0, ease: 'power4.out'});
				this.setScreenMode(0);
			} else {
				gsap.to(this.state, {duration: T_DURATION, mode: 1, ease: 'power4.out'});
				this.setScreenMode(1);
			}
		})

	}

	update() {
		super.update();
		if (!this._loaded) return;
		const t: number = this.clock.getElapsedTime();

		const active = this.active;
		this.active = this.page === 'contact';

		if(!active && this.active){
			this.activate();
		}

		if(active && !this.active) {
			gsap.killTweensOf(this.state);
			this.setScreenMode(0);
			this.state.mode = 0;
		}

		if(this.sVideo) {
			this.sVideo.image.style.display = CURRENT_PAGE.slug === 'contact' && !TRANSITION.inProgress ? 'block' : 'none';
		}

		if(!this.active) return;

		HOLE_MAT.uniforms.mode.value = SCREEN_FBO.uniforms.mode.value;
		if(this.shader) {
			this.shader.uniforms.time.value = t;
			// this.shader.uniforms.mode.value = SCREEN_FBO.uniforms.mode.value;
		}
		HOLE_MAT.uniforms.time.value = t;
		SCREEN_FBO.uniforms.time.value = t;

		if(!active) {
			this.setupButton();
		}

		if(this.sVideo) {
			if(this.sVideo.image.paused) this.sVideo.image.play();
			// this.sVideo.update();
			SCREEN_FBO.uniforms.time.value = t;
		}

		this.mouseTarget.lerp(this.mouse, .016);

		this.cc.target = this.state.mode;
		this.cc.mouseAmp = MathUtils.lerp(1, 0.2, this.state.mode);

		this.cc.update(this.mouseTarget);

		this.dof.shader.uniforms.cameraFar.value = MathUtils.lerp(150, 2, this.state.mode);
		this.dof.shader.uniforms.focalDistance.value = MathUtils.lerp(20, 1.5, this.state.mode);
		this.dof.shader.uniforms.aperture.value = MathUtils.lerp(200, .5, this.state.mode);
	}

	render() {
		if(!this.active) return;
		if(!this.videoStreamed) {
			this.videoStreamed = true;
			CustomVideoTexture.genVideoTexture(`${ASSETS_URL}basis/phonebooth/screen/screen.mp4`, (tex:CustomVideoTexture) => {
				this.sVideo = tex;
				this.sVideo.image.style.display = 'none';
				tex.flipY = false;
				SCREEN_FBO.uniforms.tVideo.value = tex;
			});
		}
		this.renderer.setClearColor(0x000000, 0);
		FboUtils.renderToFbo(this.screen,this.renderer,SCREEN_FBO);
		if(this.state.mode > .5) {
			this.renderer.setClearColor(0x000000, 1);
			this.composer.render(this.renderer, this.scene, this.camera);
			this.composer.pass(this.renderer, this.dof, false);
			this.composer.pass(this.renderer, this.fxaa, true);
		} else {
			this.renderer.setClearColor(0x000000, 1);
			this.renderer.render(this.scene, this.camera);
		}
	}

	dispose () {
		super.dispose();
	}
}