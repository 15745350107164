import { el } from "@jocabola/utils";
import { Object3D, PerspectiveCamera, Scene } from "three";
import { CSS3DObject, CSS3DRenderer, CSS3DSprite } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

const scene = new Scene();
const renderer = new CSS3DRenderer();
const cam = new PerspectiveCamera(45, 1, 0, 100);
let initialized = false;

const CSS_SCALE_UP = 100;
const CSS_SCALE_DOWN = .01;

scene.scale.multiplyScalar(CSS_SCALE_UP);

export class css3D {
	static init (width:number, height:number) {
		if(initialized) return;
		initialized = true;
		document.body.appendChild(renderer.domElement);
		renderer.domElement.style.pointerEvents = 'none';
		renderer.domElement.classList.add('texts3D__wrapper')
		renderer.setSize(width, height);
	}

	static render(camera:PerspectiveCamera) {
		if(!initialized) return;
		cam.copy(camera);
		cam.position.multiplyScalar(CSS_SCALE_UP);
		renderer.render(scene, cam);
		cam.position.multiplyScalar(CSS_SCALE_DOWN);
	}

	static add(el:CSS3DObject|CSS3DSprite) {
		scene.add(el);
	}

	static setSize(width:number, height:number) {	
		renderer.setSize(width, height);
	}
}

const scales = [
	.0015,
	.0007,
	.0007,
	.00035,
	.00035,
]

export class Text3D {
	container:CSS3DObject;
	dom:HTMLElement;
	mobileRef:Object3D;
	desktopRef:Object3D;
	visible:boolean = false;
	
	constructor(refs:Array<Object3D>, index:number) {
		this.dom = el('div');
		this.container = new CSS3DObject(this.dom);
		this.container.element.classList.add('text3D__item', `text3D__item-${index}`);
		css3D.add(this.container);

		this.dom.style.width = 'auto';
		this.dom.style.height = 'auto';

		this.container.scale.setScalar(scales[index]);
		this.setContent(index);
		
		if(refs[0].name.toLowerCase().indexOf('mobile') === -1) {
			this.mobileRef = refs[1];
			this.desktopRef = refs[0];
		} else {
			this.mobileRef = refs[0];
			this.desktopRef = refs[1];
		}

		this.updateMatrix();
	}

	setContent(index){				
		const content = document.querySelector(`[text3d="${index}"]`).cloneNode(true) as HTMLElement;
		content.removeAttribute('text3d');
		
		this.dom.appendChild(content);
	}

	updateMatrix() {
		const isPortrait = window.innerWidth < window.innerHeight;
		const ref = isPortrait ? this.mobileRef : this.desktopRef;
		ref.getWorldPosition(this.container.position);
		ref.getWorldQuaternion(this.container.quaternion);
		this.container.rotateX(-Math.PI/2);
	}

	hide(){
		if(!this.visible) return;		
		this.visible = false;
		this.container.element.style.opacity = '0';
	}

	show(){
		if(this.visible) return;
		this.visible = true;
		this.container.element.style.opacity = '1';
	}
}