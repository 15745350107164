import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText.js';
import DefaultAnimation from '../../DefaultAnimation';


gsap.registerPlugin(SplitText);

export default class CoverText extends DefaultAnimation {
  constructor(el) {
    super(el);

    this.img = this.dom.querySelector('img');
    this.split = null;

    this.createTl();
  }

  onResize(){
    super.onResize();

    const p = this.tl ? this.tl.progress() : 1;

    this.split?.revert();
    this.tl?.kill();
    this.createTl();

    if(this.visible) this.tl.progress(p);
  }

  createTl(){
    const split = this.dom.querySelectorAll('h1, h2, h3, h4, h5, h6, p');
    this.split = new SplitText(split, { type: 'chars, words' });

    this.tl = gsap.timeline({
      paused: true,
    })

    gsap.set(this.split.chars, {
      yPercent: -120,
      autoAlpha: 0,
    })

    this.tl
      .to(this.split.chars, {
        stagger: {
          each: 0.025,
          from: 'end',
        },
        autoAlpha: 1,
        yPercent: 0,
        duration: 1,
        ease: 'default'
      })
  }

  show(){
    if(this.visible) return;
    super.show();
    if(this.tl.progress() > 0) return;
    this.tl.play();
  }

  hide(){
    if(!this.visible) return;
    super.hide();
  }
}
